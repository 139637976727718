const isSelected = (field) => {
  if (
    field === "all" ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

const isSelectedNum = (field) => {
  if (
    field === 0 ||
    field === "" ||
    field === undefined ||
    field === null ||
    field === "none"
  ) {
    return false;
  } else {
    return true;
  }
};

export const genrateLblCategoryFilter = (body) => {
  const { search } = body;
  const blacklist_phoneNumber = isSelectedNum(search)
    ? `&search=${search}`
    : "";

  const url = `?${blacklist_phoneNumber}`;

  return url;
};
export const genrateVideoCategoryFilter = (body) => {
  const { search } = body;
  const blacklist_phoneNumber = isSelectedNum(search)
    ? `&search=${search}`
    : "";

  const url = `?${blacklist_phoneNumber}`;

  return url;
};

export const genrateCityFilter = (body) => {
  const { search } = body;
  const cityData = isSelectedNum(search) ? `&search=${search}` : "";

  const url = `?${cityData}`;

  return url;
};

export const genratePanelUserFiltr = (body) => {
  const {
    usergroup,
    is_active,
    tl,
    sl,
    username,
    on_call,
    break_button,
    inbound_mode,
    is_imaging_lead,
    is_homedx_lead,
    executive_type,
    ozontel_skills,
    phone,
    email,
    phonenumber,
  } = body;
  const panel_usergroup = isSelectedNum(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const panel_sl = isSelected(sl) ? `&sl=${sl}` : "";
  const panel_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const phoneNumber = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const panel_email = isSelectedNum(email) ? `&email=${email}` : "";
  const panel_executiveType = isSelected(executive_type)
    ? `&executive_type=${executive_type}`
    : "";
  const panel_ozontelSkills = isSelected(ozontel_skills)
    ? `&ozontel_skills=${ozontel_skills}`
    : "";
  const panel_userStatus = isSelectedNum(is_active)
    ? `&is_active=${is_active}`
    : "";
  const panel_Break = isSelectedNum(break_button)
    ? `&break_button=${break_button}`
    : "";
  const panel_OnCall = isSelectedNum(on_call) ? `&on_call=${on_call}` : "";
  const panel_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
  const panel_username = isSelectedNum(username) ? `&username=${username}` : "";
  const inboundMode = isSelectedNum(inbound_mode)
    ? `&inbound_mode=${inbound_mode}`
    : "";
  const imagingLead = isSelectedNum(is_imaging_lead)
    ? `&is_imaging_lead=${is_imaging_lead}`
    : "";
  const homedxLead = isSelectedNum(is_homedx_lead)
    ? `&is_homedx_lead=${is_homedx_lead}`
    : "";
  const url = `?${panel_sl}${phoneNumber}${panel_phone}${panel_email}${panel_Break}${panel_OnCall}${panel_username}${panel_usergroup}${panel_userStatus}${panel_panelUser}${inboundMode}${imagingLead}${homedxLead}${panel_executiveType}${panel_ozontelSkills}`;
  return url;
};

export const genratePanelUserCommentFilter = (body) => {
  const { panelData } = body;
  const panel_usergroup = isSelectedNum(panelData)
    ? `&panel_user_id=${panelData}`
    : "";
  const url = `?${panel_usergroup}`;
  return url;
};

export const genrateUnregisterdBookingFilter = (body) => {
  const { multiple_booking } = body;
  const unregisterd_bookingId = isSelectedNum(multiple_booking)
    ? `&multiple_booking=${multiple_booking}`
    : "";
  const url = `?${unregisterd_bookingId}`;

  return url;
};

export const generateRecordingUrl = (body) => {
  const {
    lead_status,
    panel_user,
    usergroup,
    provider,
    lead,
    booking_id,
    source,
    qc_agent,
    start_date,
    end_date,
    direction,
    phonenumber,
    status,
    callToNumber,
    category,
    city,
    lead_source,
    call_sid,
    source_type,
    hangup_clause,
  } = body;
  const lead_status_part = isSelectedNum(lead_status)
    ? `&lead_status=${lead_status}`
    : "";
  const booking_call_sid = isSelectedNum(call_sid)
    ? `&call_sid=${call_sid}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_call_to = isSelectedNum(callToNumber)
    ? `&call_to=${callToNumber}`
    : "";
  const lead_lead_source = isSelectedNum(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const lead_hangupClause = isSelectedNum(hangup_clause)
    ? `&hangup_clause=${hangup_clause}`
    : "";
  const panel_user_part = isSelectedNum(panel_user)
    ? `&panel_user=${panel_user}`
    : "";
  const provider_part = isSelectedNum(provider) ? `&provider=${provider}` : "";
  const recording_status = isSelectedNum(status)
    ? `&call_picked=${status}`
    : "";
  const recording_category = isSelectedNum(category)
    ? `&category=${category}`
    : "";
  const usergroup_category = isSelectedNum(usergroup)
    ? `&usergroup=${usergroup}`
    : "";
  const lead_part = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_part = isSelectedNum(booking_id)
    ? `&booking=${booking_id}`
    : "";
  const phonenumber_part = isSelectedNum(phonenumber)
    ? `&phonenumber=${phonenumber}`
    : "";
  const qc_agent_part = isSelectedNum(qc_agent)
    ? `&quality_user=${qc_agent}`
    : "";
  const start_date_part = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const end_date_part = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const direction_part = isSelectedNum(direction)
    ? `&direction=${direction}`
    : "";
  const booking_source_type = isSelected(source_type)
    ? `&source_type=${source_type}`
    : "";
  const url = `?${lead_hangupClause}${booking_city}${booking_call_to}${booking_source}${lead_lead_source}${lead_status_part}${booking_part}${recording_category}${recording_status}${panel_user_part}${usergroup_category}${provider_part}${lead_part}${qc_agent_part}${start_date_part}${end_date_part}${direction_part}${phonenumber_part}${booking_call_sid}${booking_source_type}`;

  return url;
};

export const generateBookingFilterUrl = (body) => {
  const {
    status,
    financeStatus,
    category,
    verificationStatus,
    source,
    agent,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    lead,
    no_call,
    phone,
    pickupAmount,
    pickup_status,
    pickup_done,
    bookingId,
    partner,
    user_group,
    slot,
    sample_registered,
    report_status,
    report_status2,
    is_pickup_receive_amount,
    booking_type,
    due_date,
    due_start_date,
    due_end_date,
    assigned_status,
    // lead_status,
    lead_call_status_id,
    route_manager,
    verified_by,
    created_date,
    journey,
    tat_status,
    client_refid,
    org_types,
    tl,
    collection_start_date,
    collection_end_date,
    barcode,
    doctor,
    sl,
    disposition,
    report_not_status,
    email,
    bucket,
    ticket_closed_date_start,
    ticket_closed_date_end,
    source_type,
    location_verified,
    lead_for,
    createdstart_date,
    createdend_date,
    location_event_status,
    center,
  } = body;

  const booking_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const centerId = isSelected(center) ? `&multiple_center=${center}` : "";
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const booking_locationMessageStatus = isSelectedNum(location_event_status)
    ? `&location_event_status=${location_event_status}`
    : "";
  const booking_category_status = isSelectedNum(lead_call_status_id)
    ? `&lead_call_status_id=${lead_call_status_id}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking_id=${bookingId}` : "";
  const booking_report_not_status = isSelectedNum(report_not_status)
    ? `&status=${report_not_status}`
    : "";
  const doctor_disposition = isSelectedNum(disposition)
    ? `&disposition=${disposition}`
    : "";
  const booking_verified_by = isSelectedNum(verified_by)
    ? `&verified_by=${verified_by}`
    : "";
  const booking_routemanager = isSelectedNum(route_manager)
    ? `&route_manager=${route_manager}`
    : "";
  const booking_tatStatus = isSelectedNum(tat_status)
    ? `&tat_status=${tat_status}`
    : "";
  const booking_clientRefId = isSelectedNum(client_refid)
    ? `&client_refid=${client_refid}`
    : "";
  const booking_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const booking_call_count = isSelectedNum(no_call)
    ? `&no_calls=${no_call}`
    : "";
  const booking_phlebojourney = isSelectedNum(journey)
    ? `&journey=${journey}`
    : "";
  const booking_partner = isSelectedNum(partner)
    ? `&multiple_partner=${partner}`
    : "";
  const booking_user_group = isSelectedNum(user_group)
    ? `&multiple_usergroup=${user_group}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";
  const booking_email = isSelectedNum(email) ? `&email=${email}` : "";
  const booking_ticketstart_date = isSelectedNum(ticket_closed_date_start)
    ? `&ticket_closed_date_start=${ticket_closed_date_start}`
    : "";
  const booking_ticketend_date = isSelectedNum(ticket_closed_date_end)
    ? `&ticket_closed_date_end=${ticket_closed_date_end}`
    : "";
  const booking_bucket = isSelectedNum(bucket) ? `&bucket=${bucket}` : "";
  const booking_createdstart_date = isSelectedNum(createdstart_date)
    ? `&start_date=${createdstart_date}`
    : "";
  const booking_createdend_date = isSelectedNum(createdend_date)
    ? `&end_date=${createdend_date}`
    : "";
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_collection_slots = isSelectedNum(slot)
    ? `&multiple_collection_slots=${slot}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_tl = isSelected(tl) ? `&tl=${tl}` : "";
  const booking_sl = isSelected(sl) ? `&sl=${sl}` : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_due_date = isSelected(due_date) ? `&due_date=${due_date}` : "";
  const booking_due_start_date = isSelected(due_start_date)
    ? `&due_start_date=${due_start_date}`
    : "";
  const booking_due_end_date = isSelected(due_end_date)
    ? `&due_end_date=${due_end_date}`
    : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const booking_barcode = isSelected(barcode) ? `&barcode=${barcode}` : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_doctor_name = isSelectedNum(doctor) ? `&doctor=${doctor}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const booking_pickup_done = isSelectedNum(pickup_done)
    ? `&pickup_done=${pickup_done}`
    : "";
  const booking_pickup_amount = isSelectedNum(pickupAmount)
    ? `&pickup_amount=${pickupAmount}`
    : "";
  const booking_finance_status = isSelectedNum(financeStatus)
    ? `&multiple_finance_status=${financeStatus}`
    : "";
  const booking_org_type = isSelectedNum(org_types)
    ? `&org_types=${org_types}`
    : "";
  const booking_booking_type = isSelectedNum(booking_type)
    ? `&booking_type=${booking_type}`
    : "";
  const booking_sample_registered = isSelectedNum(sample_registered)
    ? `&sample_registered=${sample_registered}`
    : "";
  const booking_report_status = isSelectedNum(report_status)
    ? `&multiple_report_status=${report_status}`
    : "";
  const booking_report_status2 = report_status2
    ? `&multiple_report_status=${report_status2}`
    : "";
  const booking_is_pickup_receive_amount = isSelectedNum(
    is_pickup_receive_amount
  )
    ? `&is_pickup_receive_amount=${is_pickup_receive_amount}`
    : "";
  const assignedStatus = isSelected(assigned_status)
    ? `&assigned_status=${assigned_status}`
    : "";
  const booking_collection_start_date = isSelected(collection_start_date)
    ? `&collection_start_date=${collection_start_date}`
    : "";
  const booking_collection_end_date = isSelected(collection_end_date)
    ? `&collection_end_date=${collection_end_date}`
    : "";
  const booking_source_type = isSelected(source_type)
    ? `&source_type=${source_type}`
    : "";
  const booking_location_verified = isSelected(location_verified)
    ? `&location_verified=${location_verified}`
    : "";
  const booking_lead_for = isSelectedNum(lead_for)
    ? `&lead_for=${lead_for}`
    : "";
  const url = `?${booking_sl}${booking_collection_start_date}${booking_collection_end_date}${booking_clientRefId}${booking_tatStatus}${booking_phlebojourney}${booking_verified_by}${booking_routemanager}${booking_category_status}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_report_status2}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${booking_call_count}${assignedStatus}${booking_org_type}${booking_barcode}${booking_created_date}${booking_doctor_name}${doctor_disposition}${booking_report_not_status}${booking_bucket}${booking_email}${booking_ticketstart_date}${booking_ticketend_date}${booking_lead_for}${booking_source_type}${booking_createdstart_date}${booking_createdend_date}${booking_location_verified}${booking_locationMessageStatus}${centerId}`;
  // const url = `?${booking_routemanager}${booking_category_status}${booking_client_billid}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_created_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}`;
  return url;
};
export const generateTransferdTicketFilterUrl = (body) => {
  const { booking, agent } = body;

  const ticket_booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const ticket_booking_agent = isSelectedNum(agent) ? `&agent=${agent}` : "";

  const url = `?${ticket_booking_id}${ticket_booking_agent}`;
  // const url = `?${booking_routemanager}${booking_category_status}${booking_client_billid}${booking_tl}${booking_due_date}${booking_due_start_date}${booking_due_end_date}${booking_created_date}${booking_booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}${booking_lead_id}${assignedStatus}`;
  return url;
};
export const genrateCollectionCentrLedgerFilter = (body) => {
  const { status, center, start_date, end_date, ledger_type } = body;
  const collection_center = isSelectedNum(center)
    ? `&multiple_center=${center}`
    : "";
  const collection_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const collection_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const collection_status = isSelectedNum(status) ? `&status=${status}` : "";
  const collection_ledger_type = isSelectedNum(ledger_type)
    ? `&ledger_type=${ledger_type}`
    : "";
  const url = `?${collection_start_date}${collection_end_date}${collection_status}${collection_ledger_type}${collection_center}`;
  return url;
};
export const genrateRepeatedListFilter = (body) => {
  const { lead, start_date, end_date, created_at } = body;
  const repeated_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const repeated_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const repeated_lead = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const repeated_created_at = isSelected(created_at)
    ? `&created_date=${created_at}`
    : "";
  const url = `?${repeated_start_date}${repeated_end_date}${repeated_lead}${repeated_created_at}`;
  return url;
};

export const genrateSampleHandoverFilter = (body) => {
  const {
    start_date,
    end_date,
    created_date,
    phlebo,
    booking,
    rider,
    id,
    barcode,
    batchStatus,
  } = body;
  const sampleHandover_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const sampleHandover_barcode = isSelected(barcode)
    ? `&barcode=${barcode}`
    : "";
  const sampleHandover_end_date = isSelected(end_date)
    ? `&end_date=${end_date}`
    : "";
  const sampleHandover_created_at = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const sampleHandover_phlebo = isSelectedNum(phlebo)
    ? `&phlebo=${phlebo}`
    : "";
  const sampleHandover_rider = isSelectedNum(rider) ? `&rider=${rider}` : "";
  const sampleHandover_rider_id = isSelectedNum(id) ? `&id=${id}` : "";
  const sampleHandover_booking_id = isSelectedNum(booking)
    ? `&booking=${booking}`
    : "";
  const batch_status = isSelectedNum(batchStatus)
    ? `&batch_status=${batchStatus}`
    : "";
  const url = `?${sampleHandover_barcode}${sampleHandover_rider_id}${sampleHandover_booking_id}${sampleHandover_start_date}${sampleHandover_end_date}${sampleHandover_created_at}${sampleHandover_phlebo}${sampleHandover_rider}${batch_status}`;
  return url;
};

export const generateVerifiedBookingFilterUrl = (body) => {
  const {
    status,
    financeStatus,
    category,
    verificationStatus,
    source,
    agent,
    phlebo,
    city,
    start_date,
    end_date,
    booking_date,
    collection_date,
    name,
    phone,
    pickupAmount,
    pickup_status,
    pickup_done,
    bookingId,
    partner,
    user_group,
    slot,
    sample_registered,
    report_status,
    is_pickup_receive_amount,
    type,
  } = body;
  const booking_category =
    isSelectedNum(category) && category !== "0"
      ? `&multiple_disposition=${category}`
      : "";
  const booking_status = isSelectedNum(status)
    ? `&multiple_booking_status=${status}`
    : "";
  const booking_id = isSelectedNum(bookingId) ? `&booking=${bookingId}` : "";
  const booking_partner = isSelectedNum(partner)
    ? `&multiple_partner=${partner}`
    : "";
  const booking_user_group = isSelectedNum(user_group)
    ? `&multiple_usergroup=${user_group}`
    : "";
  const verification_status = isSelectedNum(verificationStatus)
    ? `&multiple_verification_status=${verificationStatus}`
    : "";
  const booking_source = isSelectedNum(source)
    ? `&multiple_source=${source}`
    : "";
  const booking_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";
  const booking_phlebo = isSelectedNum(phlebo)
    ? `&multiple_phlebo=${phlebo}`
    : "";

  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_collection_slots = isSelectedNum(slot)
    ? `&multiple_collection_slots=${slot}`
    : "";
  const booking_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const booking_booking_date = isSelected(booking_date)
    ? `&booking_date=${booking_date}`
    : "";
  const booking_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const booking_collection_date = isSelected(collection_date)
    ? `&collection_date=${collection_date}`
    : "";
  const booking_name = isSelectedNum(name) ? `&name=${name}` : "";
  const booking_type = isSelectedNum(type) ? `&type=${type}` : "";
  const booking_phone = isSelectedNum(phone) ? `&phone=${phone}` : "";
  const booking_pickup_status = isSelectedNum(pickup_status)
    ? `&multiple_pickup_status=${pickup_status}`
    : "";
  const booking_pickup_done = isSelectedNum(pickup_done)
    ? `&pickup_done=${pickup_done}`
    : "";
  const booking_pickup_amount = isSelectedNum(pickupAmount)
    ? `&pickup_amount=${pickupAmount}`
    : "";
  const booking_finance_status = isSelectedNum(financeStatus)
    ? `&multiple_finance_status=${financeStatus}`
    : "";
  const booking_sample_registered = isSelectedNum(sample_registered)
    ? `&sample_registered=${sample_registered}`
    : "";
  const booking_report_status = isSelectedNum(report_status)
    ? `&multiple_report_status=${report_status}`
    : "";
  const booking_is_pickup_receive_amount = isSelectedNum(
    is_pickup_receive_amount
  )
    ? `&is_pickup_receive_amount=${is_pickup_receive_amount}`
    : "";
  const url = `?${booking_type}${booking_is_pickup_receive_amount}${booking_report_status}${booking_sample_registered}${booking_user_group}${booking_collection_slots}${booking_finance_status}${booking_category}${booking_id}${booking_pickup_done}${booking_partner}${booking_pickup_status}${booking_pickup_amount}${booking_agent}${booking_name}${booking_phone}${booking_phlebo}${booking_city}${booking_source}${booking_start_date}${booking_end_date}${booking_collection_date}${booking_status}${booking_booking_date}${verification_status}`;
  return url;
};
export const generateLeadsUrl = (body) => {
  const {
    lead_call_status_id,
    start_date,
    end_date,
    createdAt,
    followUpDate,
    leadSource,
    priority,
    city,
    agent,
    inbound_mode,
    phone,
    id,
    tl,
    lead_form_call,
    lead,
  } = body;
  const leads_panelUser = isSelectedNum(tl) ? `&tl=${tl}` : "";
  const inboundMode = isSelectedNum(inbound_mode)
    ? `&inbound_mode=${inbound_mode}`
    : "";
  const leads_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const leads_lead_form_call = isSelectedNum(lead_form_call)
    ? `&lead_form_call=${lead_form_call}`
    : "";
  const leads_created_date = isSelected(createdAt)
    ? `&created_date=${createdAt}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";
  const leads_followUpDate = isSelected(followUpDate)
    ? `&followup_date=${followUpDate}`
    : "";
  const leads_phone = isSelected(phone) ? `&phone=${phone}` : "";
  const leads_lead_source = isSelected(leadSource)
    ? `&multiple_lead_source=${leadSource}`
    : "";
  const leads_priority = isSelected(priority)
    ? `&multiple_priority=${priority}`
    : "";
  const leads_id = isSelected(id) ? `&id=${id}` : "";
  const leads_category =
    isSelectedNum(lead_call_status_id) && lead_call_status_id !== "0"
      ? `&multiple_category_id=${lead_call_status_id}`
      : "";
  const leads_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const leads_agent = isSelectedNum(agent) ? `&multiple_agent=${agent}` : "";

  const url = `?${leads_lead_form_call}${leads_panelUser}${leads_phone}${leads_category}${leads_agent}${leads_start_date}${leads_end_date}${leads_city}${leads_id}${leads_created_date}${leads_followUpDate}${leads_lead_source}${leads_priority}${leads_lead_id}${inboundMode}`;

  return url;
};

export const generateEmailFilterUrl = (body) => {
  const { booking, email } = body;
  const booking_id = isSelectedNum(booking) ? `&booking=${booking}` : "";
  const booking_email = isSelectedNum(email) ? `&email=${email}` : "";
  const url = `?${booking_id}${booking_email}`;

  return url;
};
export const generateBreakUrl = (body) => {
  const { agent, start_date, end_date, created_date } = body;

  const leads_agent = isSelectedNum(agent) ? `&panel_user=${agent}` : "";
  const leads_created_date = isSelected(created_date)
    ? `&created_date=${created_date}`
    : "";
  const leads_start_date = isSelected(start_date)
    ? `&start_date=${start_date}`
    : "";
  const leads_end_date = isSelected(end_date) ? `&end_date=${end_date}` : "";

  const url = `?${leads_agent}${leads_created_date}${leads_start_date}${leads_end_date}`;

  return url;
};

export const genrateTatTableFilter = (body) => {
  const {
    customer_name,
    id,
    lead,
    mobile_number,
    collection_date_start,
    collection_date_end,
    barcode,
    batch_id,
  } = body;

  const tatTable_customer_name = isSelected(customer_name)
    ? `&customer_name=${customer_name}`
    : "";
  const tatTable_booking_id = isSelectedNum(id) ? `&id=${id}` : "";
  const tatTable_barcode_id = isSelectedNum(barcode)
    ? `&barcode=${barcode}`
    : "";
  const tatTable_batch_id = isSelectedNum(batch_id)
    ? `&batch_id=${batch_id}`
    : "";
  const tatTable_lead_id = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const tatTable_mobile_number = isSelected(mobile_number)
    ? `&mobile_number=${mobile_number}`
    : "";
  const tatTable_collection_date_start = isSelected(collection_date_start)
    ? `&collection_date_start=${collection_date_start}`
    : "";
  const tatTable_collection_date_end = isSelected(collection_date_end)
    ? `&collection_date_end=${collection_date_end}`
    : "";

  const url = `?${tatTable_customer_name}${tatTable_booking_id}${tatTable_mobile_number}${tatTable_lead_id}${tatTable_collection_date_start}${tatTable_collection_date_end}${tatTable_barcode_id}${tatTable_batch_id}`;
  return url;
};
export const genrateManagePhlebos = (body) => {
  const {
    phlebo,
    zone,
    city_id,
    base_city,
    email,
    phonenumber,
    phlebo_or_rider,
  } = body;
  const baseCity = isSelected(base_city) ? `&base_city=${base_city}` : "";
  const managePhlebos_city = isSelected(city_id) ? `&city_id=${city_id}` : "";
  const managePhlebos_zone = isSelectedNum(zone) ? `&zone=${zone}` : "";
  const EmailId = isSelectedNum(email) ? `&search=${email}` : "";
  const phoneNumber = isSelectedNum(phonenumber)
    ? `&search=${phonenumber}`
    : "";
  const managePhlebos_phlebo = isSelected(phlebo) ? `&search=${phlebo}` : "";
  const managePhlebos_phleboDrm = isSelectedNum(phlebo_or_rider)
    ? `&phlebo_or_rider=${phlebo_or_rider}`
    : "";
  const url = `?${managePhlebos_city}${baseCity}${managePhlebos_zone}${managePhlebos_phlebo}${EmailId}${phoneNumber}${managePhlebos_phleboDrm}`;
  return url;
};
export const genrateCampaignsFiltr = (body) => {
  const {
    lead_source,
    gcl_id,
    campaign_name,
    campaign_type,
    did,
    marketing_number,
  } = body;
  const campaigns_lead_source = isSelectedNum(lead_source)
    ? `&lead_source=${lead_source}`
    : "";
  const campaigns_gcl_id = isSelectedNum(gcl_id) ? `&gcl_id=${gcl_id}` : "";
  const campaigns_campaign_name = isSelectedNum(campaign_name)
    ? `&campaign_name=${campaign_name}`
    : "";
  const campaigns_campaign_type = isSelectedNum(campaign_type)
    ? `&campaign_type=${campaign_type}`
    : "";
  const campaigns_did = isSelectedNum(did) ? `&did=${did}` : "";
  const campaigns_marketing_number = isSelectedNum(marketing_number)
    ? `&marketing_number=${marketing_number}`
    : "";

  const url = `?${campaigns_lead_source}${campaigns_gcl_id}${campaigns_campaign_name}${campaigns_campaign_type}${campaigns_did}${campaigns_marketing_number}`;

  return url;
};
export const genrateCenterAddressPriceFilter = (body) => {
  const {
    center_address,
    packageid,
    packagename,
    type,
    package_id,
    city_name,
  } = body;
  const centeraddress_center_address = isSelected(center_address)
    ? `&center_address=${center_address}`
    : "";
  const centeraddresspackageid = isSelected(packageid)
    ? `&packageid=${packageid}`
    : "";
  const centeraddresspackagename = isSelected(packagename)
    ? `&name=${packagename}`
    : "";
  const centeraddress_package_id = isSelected(package_id)
    ? `&package_id=${package_id}`
    : "";
  const centeraddress_type = isSelected(type) ? `&type=${type}` : "";
  const centercity_name = isSelected(city_name) ? `&?cityid=${city_name}` : "";
  const url = `?${centeraddress_center_address}${centeraddress_package_id}${centeraddress_type}${centeraddresspackageid}${centercity_name}${centeraddresspackagename}`;
  return url;
};
export const genrateZoneFilter = (body) => {
  const { city_name, is_active } = body;
  const zone_center_city = isSelectedNum(city_name)
    ? `&city_name=${city_name}`
    : "";
  const zone_is_active = isSelectedNum(is_active)
    ? `&is_active=${is_active}`
    : "";
  const url = `?${zone_center_city}${zone_is_active}`;
  return url;
};

export const genrateImagingTicektFilter = (body) => {
  const { status, lead } = body;
  const call_status = isSelectedNum(status) ? `&status=${status}` : "";
  const lead_details = isSelectedNum(lead) ? `&lead=${lead}` : "";
  const url = `?${call_status}${lead_details}`;
  return url;
};

export const genratePartnerFilter = (body) => {
  const { partner } = body;
  const manage_partner = isSelected(partner) ? `&?partner_code=${partner}` : "";
  const url = `?${manage_partner}`;
  return url;
};

export const genrateManageLabs = (body) => {
  const { city_name, lab_name } = body;
  const manageLabs_city = isSelected(city_name) ? `&?city=${city_name}` : "";
  const manageLabs_name = isSelected(lab_name) ? `&?name=${lab_name}` : "";
  const url = `?${manageLabs_city}${manageLabs_name}`;
  return url;
};

export const genrateCentersFilter = (body) => {
  const { center } = body;

  const manage_centers = isSelectedNum(center)
    ? `&?name_or_code=${center}`
    : "";

  const url = `?${manage_centers}`;
  return url;
};

export const generatePincodeFilterUrl = (body) => {
  const { city, pincode } = body;
  const booking_city = isSelectedNum(city) ? `&multiple_city=${city}` : "";
  const booking_pincode = isSelected(pincode) ? `&search=${pincode}` : "";
  const url = `?${booking_city}${booking_pincode}`;

  return url;
};
