import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader2/index";
import {
  getLblList,
  getLblMoreInfoList,
  updatedLbl,
} from "../actions/MedicoappAdminActions";
import LblModel from "../components/CreateData/addLblModal";
import EditLblModel from "../components/EditData/updateLblModal";
import { genrateLblCategoryFilter } from "../../helpers/generateUrl";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  lblList: any;
  getLblList: any;
  data: any;
  setOpenTubeModal: any;
  setTubeId: any;
  getLblMoreInfoList: any;
  lblMoreInfoList: any;
  updatedLbl: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  lblList,
  getLblList,
  data,
  setOpenTubeModal,
  setTubeId,
  getLblMoreInfoList,
  lblMoreInfoList,
  updatedLbl,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [image, setImage] = useState<any>("");
  const [viewImg, setViewImg] = useState<boolean>(false);
  const [editLblDetails, seteditLblDetails] = useState<any>({});
  const [editLblDetailsRank, seteditLblDetailsRank] = useState<any>({});
  const [openLblModal, setOpenLblModal] = useState(false);
  const [editLblCateDetails, seteditLblCateDetails] = useState<any>({});
  const [openLblCateModal, setOpenLblCateModal] = useState(false);
  const [rank, setRank] = useState<any>(
    editLblDetailsRank && editLblDetailsRank?.rank
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = lblList && lblList?.next.split("?")[1];
      getLblList(url ? `${url}` : "");
    } else if (newPage < page) {
      let url = lblList && lblList?.previous.split("?")[1];
      getLblList(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getLblList();
  }, []);

  const filterBlacklistedNumber = () => {
    const body: any = {
      search: categoryName,
    };
    const url = genrateLblCategoryFilter(body).substring(2);
    getLblList(`${url}`);
    setPage(0);
  };

  const handleViewImg = (val: any) => {
    setImage(val);
    setViewImg(true);
  };
  const handleCloseImg = () => {
    setViewImg(false);
    setImage("");
  };

  const handleLblInfoModal = (id: any) => {
    getLblMoreInfoList(id);
    setOpen1(true);
  };

  const handleUpdateLbl = (data: any) => {
    setOpenLblModal(true);
    seteditLblDetails(data);
  };

  const handleUser = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }

    if (image !== "") {
      formData.append("thumbnail", image);
    }

    updatedLbl(formData, editLblDetailsRank.id);

    history.push("/dashboard/mda/lbl");
  };

  const handleEditRank = (data: any) => {
    setRank(data?.rank); // Set the rank state to the current rank value
    seteditLblDetailsRank(data);
  };

  const handleChangeRank = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRank(e.target.value); // Update the rank state when the input value changes
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="medico-page">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>LBL</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
              >
                LBL
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className="input"
                  name="title"
                  type="text"
                  label="Name"
                  value={categoryName}
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading || categoryName === ""}
                  fullWidth
                  onClick={filterBlacklistedNumber}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/lbl")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Rank</StyledTableCell>
                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Thumbnail</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">More Info</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {lblList &&
                lblList?.results &&
                lblList?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <div>
                          {editLblDetailsRank === data ? (
                            <TextField
                              className="input"
                              name="lead"
                              type="number"
                              value={rank}
                              variant="outlined"
                              onChange={handleChangeRank}
                              style={{ width: "50%" }}
                            />
                          ) : (
                            data?.rank
                          )}
                        </div>
                        <div>
                          {editLblDetailsRank === data ? (
                            <Button
                              color="primary"
                              variant="text"
                              onClick={(e) => handleUser(e)}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => handleEditRank(data)}
                            >
                              <EditIcon />
                            </Button>
                          )}
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {editLblDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={(e) => handleUser(e)}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                            Edit Rank
                          </Button>
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.thumbnail?.includes("pdf") ? (
                          <a
                            href={data?.thumbnail}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              width: "1749px",
                              height: "2481px",
                              // display: "block",
                            }}
                          >
                            View
                          </a>
                        ) : (
                          <InsertPhotoIcon
                            onClick={() => handleViewImg(data?.thumbnail)}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.is_active === true ? "Active" : "Inactive"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <UnfoldMoreIcon
                          onClick={() => handleLblInfoModal(data?.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleUpdateLbl(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={lblList?.count || 0}
                  rowsPerPage={lblList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        onClose={handleCloseImg}
        aria-labelledby="customized-dialog-title"
        open={viewImg}
        maxWidth={false}
        PaperProps={{
          style: {
            width: "1749px",
            height: "2481px",
            overflow: "hidden",
          },
        }}
      >
        {/* <DialogTitle id="customized-dialog-title">Image</DialogTitle> */}
        <DialogContent>
          <img
            src={image}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </DialogContent>
      </Dialog>

      <LblModel open={open} setOpen={setOpen} />
      <EditLblModel
        loading={loading}
        openLblModal={openLblModal}
        getLblList={getLblList}
        lblList={lblList}
        editLblDetails={editLblDetails}
        setOpenLblModal={setOpenLblModal}
        editLblCateDetails={editLblCateDetails}
        setOpenLblCateModal={setOpenLblCateModal}
        openLblCateModal={openLblCateModal}
      />
      <Dialog
        open={open1}
        onClose={() => {
          setOpen1(false);
        }}
        maxWidth="md"
      >
        <Box margin={1}>
          <Grid container xs={12}>
            <Grid item xs={11}>
              <Typography variant="h6" gutterBottom component="div">
               More info about LBL list
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ textAlign: "right" }}>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                {/* <CloseIcon /> */}
              </IconButton>
            </Grid>
          </Grid>
          {loading ? (
            <Loader />
          ) : (
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <b>Rank</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b> Edos Package Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Lbl File</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Category Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Created</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>Updated</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.keys(lblMoreInfoList).length > 0 && (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      {lblMoreInfoList?.rank ? lblMoreInfoList?.rank : "NA"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {lblMoreInfoList?.name ? lblMoreInfoList?.name : "NA"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {lblMoreInfoList?.packages_detail[0]?.name
                        ? lblMoreInfoList?.packages_detail[0]?.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <div style={{display: "flex",flexDirection: "row"}}className="image-slider">
                        {lblMoreInfoList?.images.map(
                          (image: any, index: number) => (
                            <div key={index} className="slider-item">
                              {image.file.includes("pdf") ? (
                                <a
                                  href={`${image.file}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="pdf-link"
                                >
                                  View PDF
                                </a>
                              ) : (
                                <div className="image-container">
                                  <InsertPhotoIcon
                                    className="insert-photo-icon"
                                    onClick={() =>
                                      handleViewImg(`${image.file}`)
                                    } 
                                  />
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {lblMoreInfoList?.category_detail[0]?.name
                        ? lblMoreInfoList?.category_detail[0]?.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {moment(lblMoreInfoList?.created_at).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {moment(lblMoreInfoList?.updated_at).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </Dialog>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  lblList: state.MedicoappAdminReducer.lblList,
  lblMoreInfoList: state.MedicoappAdminReducer.lblMoreInfoList,
});

export default connect(mapStateToProps, {
  getLblList,
  getLblMoreInfoList,
  updatedLbl,
})(CustomUploader);
