import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader2/index";
import { getVisualAidList,updatedVisualAid } from "../actions/MedicoappAdminActions";
import VisualAidModel from "../components/CreateData/addVisualAidModal";
import EditVisualAidModel from "../components/EditData/updateVisualAidModal";
import { genrateVideoCategoryFilter } from "../../helpers/generateUrl";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  visualAidList: any;
  getVisualAidList: any;
  data: any;
  setOpen1: any;
  updatedVisualAid: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  visualAidList,
  getVisualAidList,
  data,
  setOpen1,
  updatedVisualAid,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<any>("");
  const [viewImg, setViewImg] = useState<boolean>(false);
  const [editVisualDetails, setEditVisualDetails] = useState<any>({});
  const [editVisualAidCateDetails, setEditVisualAidCateDetails] = useState<any>({});
  const [editVisualDetailsRank, setEditVisualDetailsRank] = useState<any>({});
  const [openVisualModal, setOpenVisualModal] = useState(false);
  const [rank, setRank] = useState<any>(
    editVisualDetailsRank && editVisualDetailsRank?.rank
  );


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = visualAidList && visualAidList?.next.split("?")[1];
      getVisualAidList(url ? `${url}` : "");
    } else if (newPage < page) {
      let url =
        visualAidList && visualAidList?.previous.split("?")[1];
      getVisualAidList(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getVisualAidList();
  }, []);

  const filterBlacklistedNumber = () => {
    const body: any = {
      search: categoryName,
    };
    const url = genrateVideoCategoryFilter(body).substring(2);
    getVisualAidList(`${url}`);
    setPage(0);
  };

  const handleViewImg = (val: any) => {
    setImage(val);
    setViewImg(true);
  };
  const handleCloseImg = () => {
    setViewImg(false);
    setImage("");
  };

  const handleUpdateVisual = (data: any) => {
    setOpenVisualModal(true);
    setEditVisualDetails(data);
    setEditVisualAidCateDetails(data);
  };

  const handleUser = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }

    if (image !== "") {
      formData.append("thumbnail", image);
    }
 
    updatedVisualAid(formData, editVisualDetailsRank.id);
    setOpenVisualModal(false);
    history.push("/dashboard/mda/visual_aid");
  };

  const handleEditRank = (data: any) => {
    setRank(data?.rank); // Set the rank state to the current rank value
    setEditVisualDetailsRank(data);
    // setOpenVisualModal(true);
  };

  const handleChangeRank = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRank(e.target.value); // Update the rank state when the input value changes
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="medico-page">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>Visual Aid</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
              >
                Visual Aid
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className="input"
                  name="title"
                  type="text"
                  label="Name"
                  value={categoryName}
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading || categoryName === ""}
                  fullWidth
                  onClick={filterBlacklistedNumber}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/visual_aid")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
               <StyledTableCell align="center">Rank</StyledTableCell>
               {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">
                  Next logical Test
                </StyledTableCell>
                <StyledTableCell align="center">Next Test</StyledTableCell>
                <StyledTableCell align="center">
                  Previous Logical Test
                </StyledTableCell>
                <StyledTableCell align="center">Previous Test</StyledTableCell>
                <StyledTableCell align="center">Thumbnail</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {visualAidList &&
                visualAidList?.results &&
                visualAidList?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                         <StyledTableCell align="center">
                          <div>
                          {editVisualDetailsRank === data ? (
                          <TextField
                            className="input"
                            name="lead"
                            type="number"
                            value={rank}
                            variant="outlined"
                            onChange={handleChangeRank}
                            style={{ width: "100%" }}
                          />
                        ) : (
                          data?.rank
                        )}
                          </div>
                          <div>
                          {editVisualDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                           onClick={(e) => handleUser(e)} 
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                            <EditIcon/>
                          </Button>
                        )}
                          </div>
                     
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {editVisualDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                           onClick={(e) => handleUser(e)} 
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                            Edit Rank
                          </Button>
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.category[0]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.next_logical_test
                          ? data?.next_logical_test
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.next_test ? data?.next_test : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.previous_logical_test
                          ? data?.previous_logical_test
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.previous_test ? data?.previous_test : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.thumbnail?.includes("pdf") ? (
                          <a
                            href={data?.thumbnail}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              width: "1749px",
                              height: "2481px",
                              // display: "block",
                            }}
                          >
                            View
                          </a>
                        ) : (
                          <InsertPhotoIcon
                            onClick={() => handleViewImg(data?.thumbnail)}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.updated_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.is_active === true ? "Active" : "Inactive"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleUpdateVisual(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  count={visualAidList?.count || 0}
                  rowsPerPageOptions={[]}
                  colSpan={6}
                  rowsPerPage={visualAidList?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        onClose={handleCloseImg}
        aria-labelledby="customized-dialog-title"
        open={viewImg}
        maxWidth={false}
        PaperProps={{
          style: {
            width: "1749px",
            height: "2481px",
            overflow: "hidden",
          },
        }}
      >
        {/* <DialogTitle id="customized-dialog-title">Image</DialogTitle> */}
        <DialogContent>
          <img
            src={image}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </DialogContent>
      </Dialog>
      <VisualAidModel open={open} setOpen={setOpen} />
      {/* <EditVisualAidModel openEdit={openEdit} setOpenEdit={setOpenEdit} /> */}
      <EditVisualAidModel
        loading={loading}
        editVisualDetails={editVisualDetails}
        setOpenVisualModal={setOpenVisualModal}
        openVisualModal={openVisualModal} editVisualAidCateDetails={editVisualAidCateDetails}      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  visualAidList: state.MedicoappAdminReducer.visualAidList,
});

export default connect(mapStateToProps, {
  getVisualAidList,
  updatedVisualAid
})(CustomUploader);
