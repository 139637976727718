import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ScienceRoundedIcon from "@mui/icons-material/ScienceRounded";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ElectricBikeIcon from "@mui/icons-material/ElectricBike";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useHistory } from "react-router-dom";
import { Paper, Grid, Tooltip } from "@material-ui/core";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { blue } from "@material-ui/core/colors";
import { getBookingById } from "../../actions/MedicoappAdminActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
    maxHeight: "100%",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenPhleboModel: Function;
  openPhlebotModel: any;
  getPhleboTimeline: any;
  phleboTimeline: any;
  bookingId: any;
  loading: any;
  getBookingById: any;
  booking: any;
  reportStatus: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  setOpenPhleboModel,
  openPhlebotModel,
  getPhleboTimeline,
  phleboTimeline,
  bookingId,
  loading,
  getBookingById,
  booking,
  reportStatus,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenPhleboModel(false);
  };

  const [openReportModal, setReportModal] = useState(false);

  const handleReportStatus = () => {
    setReportModal(true);
  };

  useEffect(() => {
    if (bookingId !== "") {
      getPhleboTimeline(bookingId);
      getBookingById(`${bookingId}/`);
    }
  }, [bookingId]);
  var Normal = "#009688";
  var Blue = "#2c387e";
  var Red = "#f50057";
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPhlebotModel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPhlebotModel}>
          <div className={classes.paper}>
            <>
              <h3 style={{ color: "#3f51b5" }}>Order Number {bookingId} </h3>
              Note:
              <small>
                <ul style={{ paddingLeft: "15px", color: "blue" }}>
                  <li>
                    if Color is Blue/Green its means everything is Normal.
                  </li>
                  <li>if Color is Red its means everything is Not Normal</li>
                </ul>
              </small>
              {phleboTimeline.report_status === "none" ? (
                <p style={{ color: "#ff784e" }}>
                  Your Booking is created ,Our Phlebo Will be reached out Soon.
                </p>
              ) : (
                ""
              )}
              {phleboTimeline.report_status === "pending" ? (
                <p style={{ color: "#ff784e" }}>Your Report is genrated Soon</p>
              ) : (
                ""
              )}
              {phleboTimeline.report_status === "partial" ? (
                <p style={{ color: "#ff784e" }}>
                  Your Sample is Collected , Your report will be generated
                  shortly.
                </p>
              ) : (
                ""
              )}
              {phleboTimeline.report_status === "consoldated" ? (
                <p style={{ color: "#ff784e" }}>
                  Your Booking is created ,Our Phlebo Will be reached out Soon.
                </p>
              ) : (
                ""
              )}
              <Grid container spacing={3}>
                {phleboTimeline.booking_status !== "reschedule" ||
                phleboTimeline.booking_status !== "resampling" ? (
                  <>
                  {/* @ts-ignore */}
                    <Timeline position="alternate">
                      <TimelineItem style={{ color: "#2c387e" }}>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: Normal }}
                        >
                          {phleboTimeline?.created_at?.substring(0, 10)
                            ? "Date :" +
                              phleboTimeline?.created_at?.substring(0, 10)
                            : " "}
                          <br />
                          {phleboTimeline?.created_at?.substring(11, 19)
                            ? "Time :" +
                              phleboTimeline?.created_at?.substring(11, 19)
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator style={{ color: "#2c387e" }}>
                          <TimelineDot style={{ color: "#2c387e" }}>
                            <CreateNewFolderIcon style={{ color: "#2c387e" }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: Normal }}
                          >
                            Booking Created
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem style={{ color: "#2c387e" }}>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{ color: Normal }}
                        >
                          {phleboTimeline?.alloted_time?.substring(0, 10)
                            ? "Date :" +
                              phleboTimeline?.alloted_time?.substring(0, 10)
                            : " "}
                          <br />
                          {phleboTimeline?.alloted_time?.substring(11, 19)
                            ? "Time :" +
                              phleboTimeline?.alloted_time?.substring(11, 19)
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator style={{ color: "#2c387e" }}>
                          <TimelineDot style={{ color: "#2c387e" }}>
                            <CreateNewFolderIcon style={{ color: "#2c387e" }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: Blue }}
                          >
                            Alloted Time
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{
                            color:
                              phleboTimeline?.phlebo_assign?.color === "normal"
                                ? Normal
                                : phleboTimeline?.phlebo_assign?.color,
                          }}
                        >
                          {phleboTimeline?.phlebo_assign?.time?.substring(0, 10)
                            ? "Date :" +
                              phleboTimeline?.phlebo_assign?.time?.substring(
                                0,
                                10
                              )
                            : " "}
                          <br />
                          {phleboTimeline?.phlebo_assign?.time?.substring(
                            11,
                            19
                          )
                            ? "Time :" +
                              phleboTimeline?.phlebo_assign?.time?.substring(
                                11,
                                19
                              )
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              color:
                                phleboTimeline?.phlebo_assign?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_assign?.color,
                            }}
                          >
                            <DeliveryDiningIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                phleboTimeline?.phlebo_assign?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_assign?.color,
                            }}
                          >
                            Phlebo Assigned
                          </Typography>
                          <Typography
                            style={{
                              color:
                                phleboTimeline?.phlebo_assign?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_assign?.color,
                            }}
                          >
                            {phleboTimeline?.phlebo_assign?.phlebo}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          variant="body2"
                          style={{
                            color:
                              phleboTimeline?.phlebo_started?.color === "normal"
                                ? Normal
                                : phleboTimeline?.phlebo_started?.color,
                          }}
                        >
                          {/* {phleboTimeline?.phlebo_start_time?.substring(11, 19)} */}
                          {phleboTimeline?.phlebo_started?.time?.substring(
                            0,
                            10
                          )
                            ? "Date :" +
                              phleboTimeline?.phlebo_started?.time?.substring(
                                0,
                                10
                              )
                            : " "}
                          <br />
                          {phleboTimeline?.phlebo_started?.time?.substring(
                            11,
                            19
                          )
                            ? "Time :" +
                              phleboTimeline?.phlebo_started?.time?.substring(
                                11,
                                19
                              )
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              color:
                                phleboTimeline?.phlebo_started?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_started?.color,
                            }}
                          >
                            <ElectricBikeIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>

                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                phleboTimeline?.phlebo_started?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_started?.color,
                            }}
                          >
                            Phlebo Started
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{
                            color:
                              phleboTimeline?.phlebo_reached?.color === "normal"
                                ? Normal
                                : phleboTimeline?.phlebo_reached?.color,
                          }}
                        >
                          {phleboTimeline?.phlebo_reached?.time?.substring(
                            0,
                            10
                          )
                            ? "Date :" +
                              phleboTimeline?.phlebo_reached?.time?.substring(
                                0,
                                10
                              )
                            : " "}
                          <br />
                          {phleboTimeline?.phlebo_reached?.time?.substring(
                            11,
                            19
                          )
                            ? "Time :" +
                              phleboTimeline?.phlebo_reached?.time?.substring(
                                11,
                                19
                              )
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              color:
                                phleboTimeline?.phlebo_reached?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_reached?.color,
                            }}
                          >
                            <DoorSlidingIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                phleboTimeline?.phlebo_reached?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_reached?.color,
                            }}
                          >
                            Phlebo Reached
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: Normal }}
                        >
                          {phleboTimeline?.pickup_date?.substring(0, 10)
                            ? "Date :" +
                              phleboTimeline?.pickup_date?.substring(0, 10)
                            : " "}
                          <br />
                          {phleboTimeline?.pickup_time?.substring(11, 19)
                            ? "Time :" +
                              phleboTimeline?.pickup_time?.substring(0, 8)
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: Normal }}>
                            <DoorSlidingIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: Normal }}
                          >
                            Pickup Status
                          </Typography>
                          <Typography style={{ color: Normal }}>
                            {phleboTimeline?.pickup_status}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      {/* <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: Normal }}
                        >

                          {phleboTimeline?.pickup_status}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: Normal }}>
                            <BookmarkAddedIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: Normal }}
                          >
                            Pickup Status
                          </Typography>
                        </TimelineContent>
                      </TimelineItem> */}
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: Normal }}
                        >
                          {phleboTimeline?.handover_to_rider_or_courier?.time?.substring(
                            0,
                            10
                          )
                            ? "Date :" +
                              phleboTimeline?.handover_to_rider_or_courier?.time?.substring(
                                0,
                                10
                              )
                            : " "}
                          <br />
                          {phleboTimeline?.handover_to_rider_or_courier?.time?.substring(
                            11,
                            19
                          )
                            ? "Time :" +
                              phleboTimeline?.handover_to_rider_or_courier?.time?.substring(
                                11,
                                19
                              )
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              color:
                                phleboTimeline?.handover_to_rider_or_courier
                                  ?.color === "normal"
                                  ? Normal
                                  : phleboTimeline?.handover_to_rider_or_courier
                                      ?.color,
                            }}
                          >
                            <BabyChangingStationIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                phleboTimeline?.handover_to_rider_or_courier
                                  ?.color === "normal"
                                  ? Normal
                                  : phleboTimeline?.handover_to_rider_or_courier
                                      ?.color,
                            }}
                          >
                            Handover To Rider or Courier
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{
                            color:
                              phleboTimeline?.sample_reached_at_lab?.color ===
                              "normal"
                                ? Normal
                                : phleboTimeline?.sample_reached_at_lab?.color,
                          }}
                        >
                          {phleboTimeline?.sample_reached_at_lab?.time?.substring(
                            0,
                            10
                          )
                            ? "Date :" +
                              phleboTimeline?.sample_reached_at_lab?.time?.substring(
                                0,
                                10
                              )
                            : " "}
                          <br />
                          {phleboTimeline?.sample_reached_at_lab?.time?.substring(
                            11,
                            19
                          )
                            ? "Time :" +
                              phleboTimeline?.sample_reached_at_lab?.time?.substring(
                                11,
                                19
                              )
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              color:
                                phleboTimeline?.sample_reached_at_lab?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.sample_reached_at_lab
                                      ?.color,
                            }}
                          >
                            <AssignmentTurnedInIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                phleboTimeline?.sample_reached_at_lab?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.sample_reached_at_lab
                                      ?.color,
                            }}
                          >
                            Sample Reached at Lab
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ fontSize: "20px", paddingTop: "15px" }}
                          // style={{ color: phleboTimeline?.sample_reached_at_lab?.color === "normal" ? Normal : phleboTimeline?.sample_reached_at_lab?.color }}
                        >
                          {phleboTimeline?.processing_labs === null ||
                          phleboTimeline?.processing_labs?.length === 0 ? (
                            <span style={{ fontSize: "20px" }}>
                              {phleboTimeline?.lab_name} Lab.
                            </span>
                          ) : phleboTimeline?.processing_labs?.length === 1 ? (
                            <p>
                              All samples are processed in{" "}
                              <span style={{ fontSize: "20px" }}>
                                {phleboTimeline?.processing_labs[0]} Lab.
                              </span>
                            </p>
                          ) : (
                            <p>
                              Samples are processed in multiple labs{" "}
                              <span style={{ fontSize: "20px" }}>
                                {phleboTimeline?.processing_labs?.toString()}
                              </span>
                            </p>
                          )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                          // style={{ color: phleboTimeline?.sample_reached_at_lab?.color === "normal" ? Normal : phleboTimeline?.sample_reached_at_lab?.color }}
                          >
                            <ScienceRoundedIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{ py: "12px", px: 2 }}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tooltip title="Check Report Status" arrow>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                              onClick={handleReportStatus}
                            >
                              Sample in - processing
                            </Typography>
                          </Tooltip>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: " blue", fontWeight: "bolder" }}
                        >
                          {String(phleboTimeline?.report_status)?.toUpperCase()}
                          <br />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "blue" }}>
                            <AssignmentTurnedInIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ color: "bule" }}
                          >
                            Report Status
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>

                      {phleboTimeline?.partial_report_sent !== null ? (
                        <>
                          <TimelineItem>
                            <TimelineOppositeContent
                              sx={{ m: "auto 0" }}
                              align="right"
                              variant="body2"
                              style={{
                                color:
                                  phleboTimeline?.partial_report_sent?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.partial_report_sent
                                        ?.color,
                              }}
                            >
                              {phleboTimeline?.partial_report_sent?.report_sent_at?.substring(
                                0,
                                10
                              )
                                ? "Date :" +
                                  phleboTimeline?.partial_report_sent?.report_sent_at?.substring(
                                    0,
                                    10
                                  )
                                : " "}
                              <br />
                              {phleboTimeline?.partial_report_sent?.report_sent_at?.substring(
                                11,
                                19
                              )
                                ? "Time :" +
                                  phleboTimeline?.partial_report_sent?.time?.substring(
                                    11,
                                    19
                                  )
                                : " "}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot
                                style={{
                                  color:
                                    phleboTimeline?.partial_report_sent
                                      ?.color === "normal"
                                      ? Normal
                                      : phleboTimeline?.partial_report_sent
                                          ?.color,
                                }}
                              >
                                <AssignmentTurnedInIcon />
                              </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                              <Typography
                                variant="h6"
                                component="span"
                                style={{
                                  color:
                                    phleboTimeline?.partial_report_sent
                                      ?.color === "normal"
                                      ? Normal
                                      : phleboTimeline?.partial_report_sent
                                          ?.color,
                                }}
                              >
                                Partial Report Sent
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        </>
                      ) : (
                        ""
                      )}

                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          style={{ color: Normal }}
                        >
                          {phleboTimeline?.report_shared?.time?.substring(0, 10)
                            ? "Date :" +
                              phleboTimeline?.report_shared?.time?.substring(
                                0,
                                10
                              )
                            : " "}
                          <br />
                          {phleboTimeline?.report_shared?.time?.substring(
                            11,
                            19
                          )
                            ? "Time :" +
                              phleboTimeline?.report_shared?.time?.substring(
                                11,
                                19
                              )
                            : " "}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot style={{ color: "#4caf50" }}>
                            <AssignmentTurnedInIcon />
                          </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{
                              color:
                                phleboTimeline?.report_shared?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.report_shared?.color,
                            }}
                          >
                            Report Shared
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </>
                ) : (
                  ""
                )}
                {phleboTimeline.booking_status === "rescheduled" ? (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      {/* @ts-ignore */}
                      <Timeline position="alternate">
                        <TimelineItem style={{ color: "#2c387e" }}>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: "#2c387e" }}
                          >
                            {phleboTimeline?.created_at?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.created_at?.substring(0, 10)
                              : " "}
                            <br />
                            {phleboTimeline?.created_at?.substring(11, 19)
                              ? "Time :" +
                                phleboTimeline?.created_at?.substring(11, 19)
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator style={{ color: "#2c387e" }}>
                            <TimelineDot style={{ color: "#2c387e" }}>
                              <CreateNewFolderIcon
                                style={{ color: "#2c387e" }}
                              />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{ color: Normal }}
                            >
                              Booking Created
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                          {/* <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            variant="body2"
                            style={{ color: "#1b5e20" }}
                          >
                          
                          </TimelineOppositeContent> */}
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.phlebo_assign?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_assign?.color,
                              }}
                            >
                              <DeliveryDiningIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.phlebo_assign?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_assign?.color,
                              }}
                            >
                              Phlebo Assigned
                            </Typography>
                            <Typography
                              style={{
                                color:
                                  phleboTimeline?.phlebo_assign?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_assign?.color,
                              }}
                            >
                              {phleboTimeline?.phlebo_assign?.phlebo}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            variant="body2"
                            style={{
                              color:
                                phleboTimeline?.phlebo_started?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_started?.color,
                            }}
                          >
                            {phleboTimeline?.phlebo_start_time?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.phlebo_start_time?.substring(
                                  0,
                                  10
                                )
                              : " "}
                            <br />
                            {phleboTimeline?.phlebo_start_time?.substring(
                              11,
                              19
                            )
                              ? "Time :" +
                                phleboTimeline?.phlebo_start_time?.substring(
                                  11,
                                  19
                                )
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.phlebo_started?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_started?.color,
                              }}
                            >
                              <ElectricBikeIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.phlebo_started?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_started?.color,
                              }}
                            >
                              Phlebo Started
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{
                              color:
                                phleboTimeline?.phlebo_reached?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_reached?.color,
                            }}
                          >
                            {phleboTimeline?.phlebo_end_time?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.phlebo_end_time?.substring(
                                  0,
                                  10
                                )
                              : " "}
                            <br />
                            {phleboTimeline?.phlebo_end_time?.substring(11, 19)
                              ? "Time :" +
                                phleboTimeline?.phlebo_end_time?.substring(
                                  11,
                                  19
                                )
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.phlebo_reached?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_reached?.color,
                              }}
                            >
                              <DoorSlidingIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.phlebo_reached?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_reached?.color,
                              }}
                            >
                              Phlebo Reached
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: Normal }}
                          >
                            {phleboTimeline?.pickup_status}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot style={{ color: Normal }}>
                              <BookmarkAddedIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{ color: Normal }}
                            >
                              Pickup Status
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: Normal }}
                          >
                            {phleboTimeline?.sample_reached_time?.substring(
                              0,
                              10
                            )
                              ? "Date :" +
                                phleboTimeline?.sample_reached_time?.substring(
                                  0,
                                  10
                                )
                              : " "}
                            <br />
                            {phleboTimeline?.sample_reached_time?.substring(
                              11,
                              19
                            )
                              ? "Time :" +
                                phleboTimeline?.sample_reached_time?.substring(
                                  11,
                                  19
                                )
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.handover_to_rider_or_courier
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline
                                        ?.handover_to_rider_or_courier?.color,
                              }}
                            >
                              <BabyChangingStationIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.handover_to_rider_or_courier
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline
                                        ?.handover_to_rider_or_courier?.color,
                              }}
                            >
                              Handover To Rider or Courier
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{
                              color:
                                phleboTimeline?.sample_reached_at_lab?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.sample_reached_at_lab
                                      ?.color,
                            }}
                          >
                            {phleboTimeline?.report_time?.substring(11, 19)}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.sample_reached_at_lab
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline?.sample_reached_at_lab
                                        ?.color,
                              }}
                            >
                              <AssignmentTurnedInIcon />
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.sample_reached_at_lab
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline?.sample_reached_at_lab
                                        ?.color,
                              }}
                            >
                              Sample Reached at Lab
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: Normal }}
                          >
                            {phleboTimeline?.report_time?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.report_time?.substring(0, 10)
                              : " "}
                            <br />
                            {phleboTimeline?.report_time?.substring(11, 19)
                              ? "Time :" +
                                phleboTimeline?.report_time?.substring(11, 19)
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot style={{ color: "#4caf50" }}>
                              <AssignmentTurnedInIcon />
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.report_shared?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.report_shared?.color,
                              }}
                            >
                              Report Shared
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                {phleboTimeline.booking_status === "resampling" ? (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      {/* @ts-ignore */}
                      <Timeline position="alternate">
                        <TimelineItem style={{ color: "#2c387e" }}>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: "#2c387e" }}
                          >
                            {phleboTimeline?.created_at?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.created_at?.substring(0, 10)
                              : " "}
                            <br />
                            {phleboTimeline?.created_at?.substring(11, 19)
                              ? "Time :" +
                                phleboTimeline?.created_at?.substring(11, 19)
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator style={{ color: "#2c387e" }}>
                            <TimelineDot style={{ color: "#2c387e" }}>
                              <CreateNewFolderIcon
                                style={{ color: "#2c387e" }}
                              />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{ color: Normal }}
                            >
                              Booking Created
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                          {/* <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            variant="body2"
                            style={{ color: "#1b5e20" }}
                          >
                          
                          </TimelineOppositeContent> */}
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.phlebo_assign?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_assign?.color,
                              }}
                            >
                              <DeliveryDiningIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.phlebo_assign?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_assign?.color,
                              }}
                            >
                              Phlebo Assigned
                            </Typography>
                            <Typography
                              style={{
                                color:
                                  phleboTimeline?.phlebo_assign?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_assign?.color,
                              }}
                            >
                              {phleboTimeline?.phlebo_assign?.phlebo}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            variant="body2"
                            style={{
                              color:
                                phleboTimeline?.phlebo_started?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_started?.color,
                            }}
                          >
                            {phleboTimeline?.phlebo_start_time?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.phlebo_start_time?.substring(
                                  0,
                                  10
                                )
                              : " "}
                            <br />
                            {phleboTimeline?.phlebo_start_time?.substring(
                              11,
                              19
                            )
                              ? "Time :" +
                                phleboTimeline?.phlebo_start_time?.substring(
                                  11,
                                  19
                                )
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.phlebo_started?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_started?.color,
                              }}
                            >
                              <ElectricBikeIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.phlebo_started?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_started?.color,
                              }}
                            >
                              Phlebo Started
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{
                              color:
                                phleboTimeline?.phlebo_reached?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.phlebo_reached?.color,
                            }}
                          >
                            {phleboTimeline?.phlebo_end_time?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.phlebo_end_time?.substring(
                                  0,
                                  10
                                )
                              : " "}
                            <br />
                            {phleboTimeline?.phlebo_end_time?.substring(11, 19)
                              ? "Time :" +
                                phleboTimeline?.phlebo_end_time?.substring(
                                  11,
                                  19
                                )
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.phlebo_reached?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_reached?.color,
                              }}
                            >
                              <DoorSlidingIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.phlebo_reached?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.phlebo_reached?.color,
                              }}
                            >
                              Phlebo Reached
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: Normal }}
                          >
                            {phleboTimeline?.pickup_status}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot style={{ color: Normal }}>
                              <BookmarkAddedIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{ color: Normal }}
                            >
                              Pickup Status
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: Normal }}
                          >
                            {phleboTimeline?.sample_reached_time?.substring(
                              0,
                              10
                            )
                              ? "Date :" +
                                phleboTimeline?.sample_reached_time?.substring(
                                  0,
                                  10
                                )
                              : " "}
                            <br />
                            {phleboTimeline?.sample_reached_time?.substring(
                              11,
                              19
                            )
                              ? "Time :" +
                                phleboTimeline?.sample_reached_time?.substring(
                                  11,
                                  19
                                )
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.handover_to_rider_or_courier
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline
                                        ?.handover_to_rider_or_courier?.color,
                              }}
                            >
                              <BabyChangingStationIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.handover_to_rider_or_courier
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline
                                        ?.handover_to_rider_or_courier?.color,
                              }}
                            >
                              Handover To Rider or Courier
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{
                              color:
                                phleboTimeline?.sample_reached_at_lab?.color ===
                                "normal"
                                  ? Normal
                                  : phleboTimeline?.sample_reached_at_lab
                                      ?.color,
                            }}
                          >
                            {phleboTimeline?.report_time?.substring(11, 19)}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              style={{
                                color:
                                  phleboTimeline?.sample_reached_at_lab
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline?.sample_reached_at_lab
                                        ?.color,
                              }}
                            >
                              <AssignmentTurnedInIcon />
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.sample_reached_at_lab
                                    ?.color === "normal"
                                    ? Normal
                                    : phleboTimeline?.sample_reached_at_lab
                                        ?.color,
                              }}
                            >
                              Sample Reached at Lab
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            style={{ color: Normal }}
                          >
                            {phleboTimeline?.report_time?.substring(0, 10)
                              ? "Date :" +
                                phleboTimeline?.report_time?.substring(0, 10)
                              : " "}
                            <br />
                            {phleboTimeline?.report_time?.substring(11, 19)
                              ? "Time :" +
                                phleboTimeline?.report_time?.substring(11, 19)
                              : " "}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot style={{ color: "#4caf50" }}>
                              <AssignmentTurnedInIcon />
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                color:
                                  phleboTimeline?.report_shared?.color ===
                                  "normal"
                                    ? Normal
                                    : phleboTimeline?.report_shared?.color,
                              }}
                            >
                              Report Shared
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.loginReducer.user,
  booking: state.MedicoappAdminReducer.booking,
  reportStatus: state.MedicoappAdminReducer.reportStatus,
});

export default connect(mapStateToProps, {
  getBookingById,
})(CommentsModal2);
