import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { updatedAboutUs } from "../../actions/MedicoappAdminActions";
import {
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
}));

type ModalProps = {
  loading: any;
  openAboutModal: boolean;
  setOpenAboutModal: Function;
  updatedAboutUs: any;
  editAboutUsDetails: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  openAboutModal,
  setOpenAboutModal,
  updatedAboutUs,
  editAboutUsDetails,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [file, setFile] = useState<any>("");
  const [isactive, setIsActive] = useState<boolean>(
    editAboutUsDetails && editAboutUsDetails?.is_active
  );
  const [rank, setRank] = useState<any>(
    editAboutUsDetails && editAboutUsDetails?.rank
  );
  const handleClose = () => {
    setOpenAboutModal(false);
    history.push("/dashboard/mda/about_us");
  };

  const SubmitAboutData = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (file !== "") {
      formData.append("file", file);
    }
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }
    if (String(isactive)) {
      formData.append("is_active", String(isactive));
    } else {
      formData.delete("is_active");
    }

    // formData.append("file", file);
    // formData.append("rank", rank);
    // formData.append("is_active", String(isactive));

    await updatedAboutUs(formData, editAboutUsDetails && editAboutUsDetails.id);
    history.push("/dashboard/mda/about_us");
  };

  const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    setRank(editAboutUsDetails?.rank);
    setIsActive(editAboutUsDetails?.is_active);
  }, [editAboutUsDetails]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAboutModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openAboutModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Update About Us
              </h4>
              <CloseIcon onClick={() => handleClose()} />
            </div>
            <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginTop: "-10px" }}>
                    <sup
                      style={{
                        fontSize: "11px",
                        marginLeft: "15px",
                        // marginTop: "15px",
                        background: "#fff",
                        color: "rgba(0, 0, 0, 0.54)",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      File upload
                    </sup>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        marginTop: "-15px",
                        padding: "10.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={fileSelectedHandler1}
                      required
                    />
                  </div>
                </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  label="Rank"
                  value={rank}
                  defaultValue={editAboutUsDetails?.rank}
                  variant="outlined"
                  onChange={(e) => setRank(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div style={{ marginLeft: "80px" }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    defaultValue={editAboutUsDetails?.is_active}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={SubmitAboutData}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/about_us")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updatedAboutUs,
})(CommentsModal2);
