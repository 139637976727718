import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import {
  createFaqs,
  getLblFaqsData,
  getLblFqsDataList,
  deleteLblFqs,
} from "../../actions/MedicoappAdminActions";
import { TextField, Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import styles from "./style.module.css";
import Loader from "../Loader2/index";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { withStyles } from "@material-ui/core";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    maxHeight: "800px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    overflow: "scroll",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
  input: {
    height: 40,
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
}));

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type ModalProps = {
  open: boolean;
  setOpen: Function;
  createFaqs: any;
  getLblFaqsData: any;
  categoryList: any;
  lblFaqsData: any;
  loading: any;
  loadingData: any;
  getLblFqsDataList: any;
  lblFaqsList: any;
  lblFaqsDataList: any;
  deleteLblFqs: any;
  lblFqaDataDelete: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  setOpen,
  createFaqs,
  getLblFaqsData,
  categoryList,
  lblFaqsData,
  loading,
  loadingData,
  getLblFqsDataList,
  lblFaqsDataList,
  lblFaqsList,
  deleteLblFqs,
  lblFqaDataDelete,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [lblFaqs, setLblFaqs] = useState<any>("");
  const [question, setQuestion] = useState<any>("");
  const [answer, setAnswer] = useState<any>("");
  const [rank, setRank] = useState<any>();
  const [errorFaqsIndex, setErrorFaqsIndex] = useState<any>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [openPop, setOpenPop] = React.useState(false);
  const [selected, setSelected] = useState<any>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setFaqsList("");
  };

  const handleCreateLbl = async () => {
    setButtonDisable(true);
    const errorRankIndex: number[] = [];
    const errorMessages: string[] = [];
    if (faqsList?.length) {
      let ranks: any = {};
      faqsList.forEach((faq: any, index: number) => {
        if (ranks[faq?.rank] !== undefined) {
          errorRankIndex.push(ranks[faq?.rank]);
          errorRankIndex.push(index);
          errorMessages.push("Rank must be unique");
        } else {
          ranks[faq?.rank] = index;
          errorMessages.push("");
        }
      });
    }

    setErrorMessages(errorMessages);

    if (errorRankIndex?.length) {
      //@ts-ignore
      setErrorFaqsIndex(errorRankIndex);
      return;
    }
    const body: any = {
      lbl: lblFaqs,
      faqs: faqsList,
      // rank: rank,
    };
    await createFaqs(body);
    history.push("/dashboard/mda/lbl_faqs");
  };

  const handleAddClick = () => {
    setFaqsList([
      ...faqsList,
      {
        question: "",
        answer: "",
        rank: Number,
      },
    ]);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...faqsList];
    list.splice(index, 1);
    setFaqsList(list);
  };

  const [faqsList, setFaqsList] = useState<any>([
    {
      question: "",
      answer: "",
    },
  ]);

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-`".includes(e.key) &&
      e.preventDefault()
    );
  };
  //Shown List
  const [displayData, setDisplayData] = useState<any>([]);
  useEffect(() => {
    const storeData: any = [];
    lblFaqsDataList &&
      lblFaqsDataList?.results?.length > 0 &&
      lblFaqsDataList?.results.map((list: any) => {
        storeData.push({
          ...list,
        });
      });
    setDisplayData(storeData);
  }, [lblFaqsDataList]);

  //pop-up open deleted

  const openPopup = (val: any) => {
    setSelected(val);
    setOpenPop(true);
  };

  const closePopup = (val: any) => {
    setSelected("");
    setOpenPop(false);
  };

  const deletePackage = async (val: any) => {
   await deleteLblFqs(`${selected?.id}`);
    getLblFqsDataList(`?lbl=${lblFaqs}`);
    getLblFaqsData();
    closePopup("");
  };

  useEffect(() => {
    if (buttonDisable) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setButtonDisable(false);
      }, 20000);
    }
  }, [buttonDisable]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                  width: "100px",
                  whiteSpace: "nowrap",
                }}
                id="transition-modal-title"
              >
                Add FAQs
              </h4>
              <Grid
                container
                spacing={1}
                direction="row"
                style={{ marginLeft: "100px" }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    id="lbl"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setLblFaqs(newValue.id);
                        getLblFqsDataList(`?lbl=${newValue?.id}`);
                      }
                    }}
                    options={lblFaqsData || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    // multiple
                    getOptionLabel={(option: any) => option.name}
                    getOptionDisabled={(option: any) => {
                      return lblFaqs === option?.id;
                    }}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getLblFaqsData(newInputValue);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setLblFaqs([]);
                      }
                    }}
                    classes={{ inputRoot: styles.padding }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Lbl Package"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0px !important",
                        }}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <Grid container spacing={1} direction="row">
              {displayData?.length > 0 ? (
                <>
                  <div style={{ width: "100%" }} className="data-table">
                    <TableContainer
                      className={classes.tableContainer}
                      // component={Paper}
                      style={{ maxHeight: "700px" }}
                    >
                      {loadingData ? (
                        <Loader />
                      ) : (
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                Rank
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Questions
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Answers
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Delete
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {displayData.length > 0 &&
                              displayData.map((list: any) => {
                                return (
                                  <>
                                    <StyledTableRow>
                                      <StyledTableCell align="center">
                                        {list?.rank}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {list?.question}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {list?.answer}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        <DeleteOutlineIcon
                                          sx={{ m: 1 }}
                                          onClick={() => openPopup(list)}
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </>
                                );
                              })}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                    <Dialog open={openPop} aria-labelledby="form-dialog-title">
                      <Grid
                        container
                        spacing={2}
                        style={{
                          margin: 10,
                          width: "600px!important",
                          marginLeft: "140px",
                        }}
                      >
                        <Grid item xs={12}>
                       <b>Are you sure you want to delete this file?</b>  
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => deletePackage(selected)}
                          >
                            Yes
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => closePopup("cancel")}
                          >
                            No
                          </Button>
                        </Grid>
                      </Grid>
                    </Dialog>
                  </div>
                </>
              ) : (
                ""
              )}
              {faqsList &&
                faqsList.map((faq: any, index: number) => (
                  <div key={faq.id}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item xs={12} sm={1}>
                        <Grid item md={1}>
                          {faqsList?.length === 0 && (
                            <AddCircleIcon
                              style={{
                                marginTop: "15px",
                                marginBottom: "1rem",
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                              }}
                              onClick={handleAddClick}
                            />
                          )}
                        </Grid>
                        <Grid item md={1}>
                          {faqsList.length - 1 === index ? (
                            ""
                          ) : (
                            <HighlightOffIcon
                              style={{
                                marginTop: "15px",
                                marginBottom: "1rem",
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleRemoveClick(index)}
                            />
                          )}
                          {faqsList.length - 1 === index && faqsList.length && (
                            <AddCircleIcon
                              style={{
                                marginTop: "15px",
                                marginBottom: "1rem",
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                              }}
                              onClick={handleAddClick}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          className="input"
                          name="rank"
                          type="number"
                          placeholder="Rank"
                          //  value={rank}
                          variant="outlined"
                          // onChange={(e) => setRank(e.target.value)}
                          onChange={(e) => {
                            const updatedList = [...faqsList];
                            updatedList[index].rank = Number(e.target.value);
                            setRank(updatedList);
                          }}
                          style={{
                            marginTop: "15px",
                            width: "100%",
                            height: "50px",
                          }}
                          required
                          error={errorFaqsIndex?.includes(index)}
                          helperText={errorMessages[index] || ""}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {/* <p>Question</p> */}
                        <textarea
                          style={{ marginTop: "15px", width: "100%" }}
                          name="question"
                          placeholder="Questions"
                          rows={2}
                          cols={30}
                          value={faq.question}
                          onChange={(e) => {
                            const updatedList = [...faqsList];
                            updatedList[index].question = e.target.value;
                            setFaqsList(updatedList);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        {/* <p>Answer</p> */}
                        <textarea
                          style={{ marginTop: "15px", width: "100%" }}
                          name="answer"
                          placeholder="Answers"
                          rows={2}
                          cols={30}
                          value={faq.answer}
                          onChange={(e) => {
                            const updatedList = [...faqsList];
                            updatedList[index].answer = e.target.value;
                            setFaqsList(updatedList);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={handleCreateLbl}
                  disabled={buttonDisable}
                >
                  Add{loading && <CircularProgress size={24} />}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/lbl_faqs")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  loadingData: state.MedicoappAdminReducer.loadingData,
  categoryList: state.MedicoappAdminReducer.categoryList,
  lblFaqsData: state.MedicoappAdminReducer.lblFaqsData,
  lblFaqsList: state.MedicoappAdminReducer.lblFaqsList,
  lblFaqsDataList: state.MedicoappAdminReducer.lblFaqsDataList,
  lblFqaDataDelete: state.MedicoappAdminReducer.lblFqaDataDelete,
});

export default connect(mapStateToProps, {
  createFaqs,
  getLblFaqsData,
  getLblFqsDataList,
  deleteLblFqs,
})(CommentsModal2);
