import {
  ACTIONS,
  QualityExecutiveTypes,
} from "../interfaces/actionTypes/MedicoappAdminType";

type InitialState = {
  lblCategoryList: Array<any>;
  lblFaqsList: Array<any>;
  videoCategoryList: Array<any>;
  videoList: Array<any>;
  lblFqaDataDelete: Array<any>;
  visualAidCategoryList: Array<any>;
  visualAidList: Array<any>;
  adminCityList: Array<any>;
  cityData: Array<any>;
  aboutUsList: Array<any>;
  lblList: Array<any>;
  lblMoreInfoList: Array<any>;
  categoryList: Array<any>;
  lblFaqsDataList: Array<any>;
  lblCategoryData: Array<any>;
  packageEdosData: Array<any>;
  videCategoryData: Array<any>;
  pin_code: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  packageList: Array<any>;
  checkGeoFenceStatus: boolean;
  couponList: Array<any>;
  lblFaqsData: Array<any>;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  userTableData: Array<any>;
  ozontelskilldata: Array<any>;
  comment: any;
  data: any;
  users: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  all_recordings_backup: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  user_panel: object;
  agentBYUserGroup: object;
  callRemark: object;
  loading: boolean;
  loadingData: boolean;
  arealoading: boolean;
  breakData: Array<any>;
  verificationBookingDetails: Array<any>;
  coupon: Object;
  unmaskedNumber: Array<any>;
  additional_booking: object;
  mapmyindiaAccessToken: Array<any>;
  leadStatus: Array<any>;
  prescription: Array<any>;
  all_lead_tatataly: object;
  addressType: Array<any>;
  notificationMessage: Array<any>;
  userDetails: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  qualityScoringReport: Array<any>;
  get_calls_data: Array<any>;
  post_quality_score: Array<any>;
  get_quality_scoring_report_for_backup: Array<any>;
  centerInfo: Array<any>;
  zone: Object;
  timeslotsList: Object;
  updatedPanelUser: Object;
  failedReport: Array<any>;
  blogList: Object;
  blog: Object;
  blogBySlug: Object;
  blogCategories: Object;
  blogTags: Object;
  blogImages: Object;
  pack: Object;
  cityPriceList: Object;
  attendenceList: Object;
  autoAssignStatus: boolean;
  createBooking: Object;
  rcFreshLead: Array<any>;
  packageTags: Array<any>;
  incorrectNumberLeads: Array<any>;
  createPanelUser: object;
  labCredential: Array<any>;
  updateLabCredential: Array<any>;
  dispositions: object;
  dispositionData: Array<any>;
  zoneComment: any;
  sameNumberBooking: boolean;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  bookingListWithTatTime: Array<any>;
  additionalBookingListWithTatTime: Array<any>;
  smsReportSend: Array<any>;
  todayCallDetails: object;
  updateUserStatus: object;
  topRecord: object;
  phleboCurrentLocation: Array<any>;
  smsDetails: Array<any>;
  manualreportsend: Array<any>;
  emailDetails: Array<any>;
  reportStatus: Array<any>;
  labDetails: Array<any>;
  reportDownload: Array<any>;
  panelUserList: Array<any>;
  language: Array<any>;
  dialer: Array<any>;
  orgTypesList: Array<any>;
  chatSkillsList: Array<any>;
  usersList: Array<any>;
  otherUsersList: Array<any>;
  mapBooking: Array<any>;
  userListData: Array<any>;
  panelComments: Array<any>;
  assignedPanel: object;
  allBookings: object;
  bookingJourney: Array<any>;
  agentdata: Array<any>;
  todayMissedCallsList: Array<any>;
  individualWhatsappChat: Array<any>;
  currentBooking: Array<any>;
  cityWisePhlebos: Array<any>;
  logistictatdata: Array<any>;
  verifiedbylist: Array<any>;
  additionMember: Array<any>;
  qrcode: Array<any>;
  signature: Array<any>;
  packageCategory: Array<any>;
  sampleHandOverData: Array<any>;
  sampleJourneyComments: Object;
  collectionSlot: Array<any>;
  redCoin: Array<any>;
  subCenter: Array<any>;
  reportData: Array<any>;
  healthTestParameter: Array<any>;
  parameterCount: Array<any>;
  imagingTimeSlots: Array<any>;
  createIMGBooking: object;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  bookingCall: Array<any>;
  salesLeadList: Array<any>;
  teamLeadList: Array<any>;
  phlebo_rider_due: Array<any>;
  imagingTicket: Array<any>;
  imagingTicketcount: Array<any>;
  topRcFreshLeads: object;
  imaginraiseTicket: Array<any>;
  paymentRefundList: Array<any>;
  performanceData: Array<any>;
  dashboardOverview: Object;
  batchbookingamount: Array<any>;
  batchPayment: Object;
  userloading: boolean;
  updatePhlebos: Object;
  phleboComment: any;
  zoneList: Object;
  createPhlebo: Object;
  phleboPhone: Object;
  paymentInformation: Array<any>;
  riderList: Array<any>;
  disposition: Array<any>;
  bookingComplaints: Array<any>;
  ticketLoader: boolean;
  ticketCommentsForDocuments: Array<any>;
  ticketComments: Array<any>;
  userData: Array<any>;
  dispositionList: Array<any>;

  bookingListLoader: boolean;
  campaingsDetails: object;
  assignedloader: boolean;
  packageComments: Array<any>;
  cityAlias: Array<any>;
  cityComments: Array<any>;
  phleboList: Array<any>;
  phleboListAuto: Array<any>;
  phleboData: Array<any>;
  ltvLead: Object;
  whatsappTemplate: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  geoCheckError: string;
  geoLoading: boolean;
  centreData: Object;
  usersData: Object;
  packageAlias: Array<any>;
  bookinSubDisposition: Array<any>;
  panelUserComment: Array<any>;
  phleboTimeline: Array<any>;
  locationVerified: Array<any>;
  addressDetailsLatLong: Array<any>;
  leadSourceData: Array<any>;
  agentsListnewdata: Array<any>;
  userEditData: Array<any>;
  partnerList: Array<any>;
  partnerData: Array<any>;
  labLogs: Array<any>;
  getCenterData: Array<any>;
  paginationComment: Array<any>;
  Phlebosloader: boolean;
  userTypesList: Array<any>;
  area: Object;
  areaComments: Array<any>;
  partnerLoading: boolean;
  cityLoading: boolean;
};

const initialState: InitialState = {
  lblCategoryList: [],
  lblFaqsList: [],
  lblFaqsData: [],
  videoCategoryList: [],
  videoList: [],
  lblFqaDataDelete: [],
  visualAidCategoryList: [],
  visualAidList: [],
  adminCityList: [],
  cityData: [],
  aboutUsList: [],
  lblList: [],
  lblMoreInfoList: [],
  categoryList: [],
  lblFaqsDataList: [],
  lblCategoryData: [],
  packageEdosData: [],
  videCategoryData: [],
  users: {},
  batchbookingamount: [],
  updatedPanelUser: {},
  imaginraiseTicket: [],
  assignedPanel: {},
  topRcFreshLeads: {},
  prescription: [],
  areaList: [],
  area: {},
  zone: {},
  timeslotsList: {},
  allBookings: {},
  blogList: {},
  blog: {},
  blogBySlug: {},
  blogCategories: {},
  blogTags: {},
  blogImages: {},
  coupon: {},
  pack: {},
  cityPriceList: {},
  additional_booking: {},
  attendenceList: {},
  autoAssignStatus: false,
  loading: false,
  loadingData: false,
  arealoading: false,
  pin_code: [],
  packageTags: [],
  currentBooking: [],
  cities: [],
  agentsList: [],
  rcFreshLead: [],
  packageList: [],
  couponList: [],
  qrcode: [],
  createBooking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  comment: {},
  data: {},
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  updateUserStatus: {},
  call_status: {},
  all_lead: {},
  mapBooking: [],
  all_lead_tatataly: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  updateLabCredential: [],
  dispositions: {},
  zoneComment: {},
  incorrectNumberLeads: [],
  sameNumberBooking: false,
  failedReport: [],
  dispositionData: [],
  cityWisePhlebos: [],
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  smsReportSend: [],
  breakData: [],
  todayCallDetails: {},
  topRecord: {},
  verificationBookingDetails: [],
  smsDetails: [],
  emailDetails: [],
  reportStatus: [],
  centerInfo: [],
  labDetails: [],
  notificationMessage: [],
  userDetails: [],
  reportDownload: [],
  phleboCurrentLocation: [],
  panelUserList: [],
  language: [],
  dialer: [],
  orgTypesList: [],
  chatSkillsList: [],
  usersList: [],
  otherUsersList: [],
  createPanelUser: {},
  userListData: [],
  areaComments: [],
  panelComments: [],
  userTableData: [],
  ozontelskilldata: [],
  collectionSlot: [],
  unmaskedNumber: [],
  agentdata: [],
  manualreportsend: [],
  bookingJourney: [],
  individualWhatsappChat: [],
  todayMissedCallsList: [],
  bookingListWithTatTime: [],
  additionalBookingListWithTatTime: [],
  checkGeoFenceStatus: false,
  verifiedbylist: [],
  additionMember: [],
  signature: [],
  packageCategory: [],
  sampleHandOverData: [],
  sampleJourneyComments: {},
  redCoin: [],
  subCenter: [],
  reportData: [],
  healthTestParameter: [],
  parameterCount: [],
  leadStatus: [],
  imagingTimeSlots: [],
  createIMGBooking: {},
  multiplePackage: [],
  displayCities: [],
  mapmyindiaAccessToken: [],
  geoFencesMapMyIndia: [],
  addressType: [],
  bookingCall: [],
  salesLeadList: [],
  teamLeadList: [],
  phlebo_rider_due: [],
  imagingTicket: [],
  imagingTicketcount: [],
  paymentRefundList: [],
  performanceData: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  dashboardOverview: {},
  logistictatdata: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  batchPayment: [],
  callRemark: {},
  all_recordings_backup: {},
  get_calls_data: [],
  post_quality_score: [],
  userloading: false,
  updatePhlebos: {},
  phleboComment: {},
  zoneList: {},
  createPhlebo: {},
  phleboPhone: {},
  paymentInformation: [],
  riderList: [],
  disposition: [],
  bookingComplaints: [],
  ticketLoader: false,
  ticketCommentsForDocuments: [],
  ticketComments: [],
  userData: [],
  dispositionList: [],

  bookingListLoader: false,
  campaingsDetails: {},
  assignedloader: false,
  packageComments: [],
  cityComments: [],
  cityAlias: [],
  phleboList: [],
  phleboListAuto: [],
  phleboData: [],
  ltvLead: {},
  whatsappTemplate: [],
  sendLocationOnWhatsapp: [],
  geoCheckError: "",
  geoLoading: false,
  centreData: {},
  usersData: {},
  packageAlias: [],
  bookinSubDisposition: [],
  panelUserComment: [],
  phleboTimeline: [],
  locationVerified: [],
  addressDetailsLatLong: [],
  leadSourceData: [],
  agentsListnewdata: [],
  userEditData: [],
  labCredential: [],
  labLogs: [],
  getCenterData: [],
  paginationComment: [],
  Phlebosloader: false,
  userTypesList: [],
  partnerList: [],
  partnerData: [],
  partnerLoading: false,
  cityLoading: false,
};

const MedicoappAdminReducer = (
  state = initialState,
  action: QualityExecutiveTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_LBL_CATEGORY_LIST:
      return {
        ...state,
        lblCategoryList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LBL_FAQS:
      return {
        ...state,
        lblFaqsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LBL_FAQS_DATA:
      return {
        ...state,
        lblFaqsData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VIDEO_CATEGORY_LIST:
      return {
        ...state,
        videoCategoryList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VIDEO_LIST:
      return {
        ...state,
        videoList: action.payload,
        loading: false,
      };
    case ACTIONS.DELETE_FAQS:
      return {
        ...state,
        lblFqaDataDelete: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VISUAL_AID_CATEGORY_LIST:
      return {
        ...state,
        visualAidCategoryList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VISUAL_AID_LIST:
      return {
        ...state,
        visualAidList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADMIN_CITY_LIST:
      return {
        ...state,
        adminCityList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_DATA:
      return {
        ...state,
        cityData: action.payload,
        cityLoading: false,
      };
    case ACTIONS.GET_ABOUT_US_LIST:
      return {
        ...state,
        aboutUsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LBL_LIST:
      return {
        ...state,
        lblList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LBL_MORE_INFO_LIST:
      return {
        ...state,
        lblMoreInfoList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LBL_FAQS_DATA_LIST:
      return {
        ...state,
        lblFaqsDataList: action.payload,
        loadingData: false,
      };
    case ACTIONS.GET_LBL_CATEGORY_DATA:
      return {
        ...state,
        lblCategoryData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_EDOS:
      return {
        ...state,
        packageEdosData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VIDEO_CATEGORY_DATA:
      return {
        ...state,
        videCategoryData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_EDIT_DATA:
      return {
        ...state,
        userEditData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RECORDINGS_BACKUP:
      return {
        ...state,
        all_recordings_backup: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_REMARK_ON_CALL:
      return {
        ...state,
        callRemark: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS_DATA:
      return {
        ...state,
        phleboData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        partnerLoading: false,
      };
    case ACTIONS.GET_PARTNERS_AUTO:
      return {
        ...state,
        partnerData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_AREA_LOADING:
      return {
        ...state,
        arealoading: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_TO_TATATALE:
      return {
        ...state,
        all_lead_tatataly: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALLS_DATA:
      return {
        ...state,
        get_calls_data: action.payload,
        loading: false,
      };
    case ACTIONS.POST_QUALITY_SCORE:
      return {
        ...state,
        post_quality_score: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
      return {
        ...state,
        get_quality_scoring_report_for_backup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_LIST_DATA:
      return {
        ...state,
        userTableData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OZONTEL_SKILL_DATA:
      return {
        ...state,
        ozontelskilldata: action.payload,
        loading: false,
      };
    case ACTIONS.SET_USER_LOADING:
      return {
        ...state,
        Userloading: action.payload,
      };
    case ACTIONS.UPDATE_PHLEBOS:
      return {
        ...state,
        updatePhlebos: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_COMMENTS:
      return {
        ...state,
        phleboComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_NEW_PHLEBO:
      return {
        ...state,
        createPhlebo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_BY_PHONE:
      return {
        ...state,
        phleboPhone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
        ...state,
        disposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        ticketLoader: false,
        loading: false,
      };
    case ACTIONS.SET_NEW_TICKET_LOADING:
      return {
        ...state,
        ticketLoader: action.payload,
      };
    case ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS:
      return {
        ...state,
        ticketCommentsForDocuments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA:
      return {
        ...state,
        panelUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DIALER:
      return {
        ...state,
        dialer: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ORG_TYPES:
      return {
        ...state,
        orgTypesList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CHAT_SKILLS_LISTS:
      return {
        ...state,
        chatSkillsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_USERS:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OTHER_USERS:
      return {
        ...state,
        otherUsersList: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATED_PANEL_USER:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.GET_CAMPAINGS_DETAILS:
      return {
        ...state,
        campaingsDetails: action.payload,
        loading: false,
      };

    case ACTIONS.GET_LEAD_DISPOSITION_LIST:
      return {
        ...state,
        dispositionList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING:
      return {
        ...state,
        allBookings: action.payload,
        assignedloader: false,
      };
    case ACTIONS.SET_ASSIGNED_LOADING:
      return {
        ...state,
        assignedloader: action.payload,
      };
    case ACTIONS.GET_MAP_BOOKING_LIST:
      return {
        ...state,
        mapBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_CURRENT_LOCATION:
      return {
        ...state,
        phleboCurrentLocation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_ALIAS:
      return {
        ...state,
        cityAlias: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_COMMENTS:
      return {
        ...state,
        cityComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_COMMENTS:
      return {
        ...state,
        packageComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RC_FRESH_LEADS:
      return {
        ...state,
        topRcFreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET:
      return {
        ...state,
        imagingTicket: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.GET_CENTER:
      return {
        ...state,
        centreData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        usersData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_ALIAS:
      return {
        ...state,
        packageAlias: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_SUB_DISPOSITION:
      return {
        ...state,
        bookinSubDisposition: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_COMMENT:
      return {
        ...state,
        panelUserComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_TIMELINE:
      return {
        ...state,
        phleboTimeline: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_CALLS:
      return {
        ...state,
        bookingCall: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_NEW_DATA:
      return {
        ...state,
        agentsListnewdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD:
      return {
        ...state,
        salesLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_COMMENT:
      return {
        ...state,
        panelComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_LOG:
      return {
        ...state,
        labLogs: action.payload,
        loading: false,
      };
    case ACTIONS.RESET_LOGS:
      return {
        ...state,
        labLogs: [],
        packageLogs: [],
      };
    case ACTIONS.GET_CENTER_DATA:
      return {
        ...state,
        getCenterData: [],
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_TYPES:
      return {
        ...state,
        userTypesList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA_COMMENTS:
      return {
        ...state,
        areaComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS_AUTO:
      return {
        ...state,
        phleboListAuto: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAGINATION_COMMENT:
      return {
        ...state,
        paginationComment: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default MedicoappAdminReducer;
// 
