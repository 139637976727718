import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import {
  updatedVideo,
  getVideoCategoryData,
  updatedVideoCategory,
} from "../../actions/MedicoappAdminActions";
import {
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./style.module.css";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
  input: {
    height: 40,
  },
  dialog: {
    zIndex: 1,
  },
}));

type ModalProps = {
  loading: any;
  openVideoModal: boolean;
  setOpenVideoModal: Function;
  editVideoDetails: any;
  updatedVideo: any;
  getVideoCategoryData: any;
  videCategoryData: any;
  openVideoCategoryModal: boolean;
  setOpenVideoCategoryModal: Function;
  editVideoCategoryDetails: any;
  updatedVideoCategory: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  openVideoModal,
  setOpenVideoModal,
  editVideoDetails,
  updatedVideo,
  getVideoCategoryData,
  videCategoryData,
  openVideoCategoryModal,
  setOpenVideoCategoryModal,
  editVideoCategoryDetails,
  updatedVideoCategory,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [videoName, setVideoName] = useState<any>(
    editVideoDetails && editVideoDetails?.name
  );
  const [isactive, setIsActive] = useState<boolean>(
    editVideoDetails && editVideoDetails?.is_active
  );
  const [rank, setRank] = useState<any>(
    editVideoDetails && editVideoDetails?.rank
  );
  const [url, setUrl] = useState<any>(
    editVideoDetails && editVideoDetails?.url
  );
  const [image, setImage] = useState<any>("");
  const [videoCategory, setVideoCategory] = useState<any>(
    editVideoDetails && editVideoDetails?.category_name
  );
  const [disLeadSourceDef, setVideoCategoryDef] = useState<string>("");
  const [showTextField, setShowTextField] = useState(false);
  const [videoCategoryName, setVideoCategoryName] = useState("");
  const [openInfo, setOpenInfo] = React.useState(false);
  const [imageCate, setImageCate] = useState<any>(null);

  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  const handleAddClick = () => {
    setModalStatus(true);
  };

  const handleRemoveClick = () => {
    setModalStatus(false);
    setVideoCategoryName("");
  };

  const handleClose = () => {
    setOpenVideoCategoryModal(false);
  };

  const SubmitVideoData = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();

    if (videoName) {
      formData.append("name", videoName);
    } else {
      formData.delete("name");
    }
    if (String(isactive)) {
      formData.append("is_active", String(isactive));
    } else {
      formData.delete("is_active");
    }
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }
    if (url) {
      formData.append("url", url);
    } else {
      formData.delete("url");
    }
    if (image !== "") {
      formData.append("thumbnail", image);
    }
    if (videoCategory) {
      formData.append("category", videoCategory);
    } else {
      formData.delete("category");
    }

    await updatedVideo(formData, editVideoDetails && editVideoDetails.id);
    history.push("/dashboard/mda/video_list");
  };

  const fileSelectedHandlerImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageCate(e.target.files[0]);
    }
  };

  useEffect(() => {
    setVideoName(editVideoDetails?.name);
    setRank(editVideoDetails?.rank);
    setIsActive(editVideoDetails?.is_active);
    setUrl(editVideoDetails?.url);
    setVideoCategoryDef(editVideoDetails?.category_name);
  }, [editVideoDetails]);

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-`".includes(e.key) &&
      e.preventDefault()
    );
  };

  const disableCenter = async (data: any) => {
    openModalFun(data);
  };

  const [modalStatus, setModalStatus] = useState<boolean>(false);

  const openModalFun = (val: any) => {
    setVideoCategoryName("");
    setModalStatus(true);
  };

  const closeModal = () => {
    setVideoCategoryName("");
    setModalStatus(false);
  };

  const updateModel = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", videoCategoryName);

    await updatedVideoCategory(
      formData,
      editVideoCategoryDetails && editVideoCategoryDetails.id
    );
    closeModal();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openVideoModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openVideoModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Update Video
              </h4>
              <CloseIcon onClick={() => setOpenVideoModal(false)} />
            </div>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="videoName"
                  type="text"
                  value={videoName}
                  defaultValue={editVideoDetails?.rank}
                  className="input"
                  label="Video Name"
                  variant="outlined"
                  onChange={(e: any) => setVideoName(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="url"
                  type="text"
                  label="Url"
                  value={url}
                  variant="outlined"
                  onChange={(e) => setUrl(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  label="Rank"
                  value={rank}
                  defaultValue={editVideoDetails?.rank}
                  variant="outlined"
                  onChange={(e) => setRank(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="category"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setVideoCategory(obj.id);
                    }
                  }}
                  options={videCategoryData || []}
                  inputValue={disLeadSourceDef}
                  freeSolo
                  blurOnSelect
                  aria-required
                  loading={loading}
                  getOptionLabel={(option: any) => option && option.name}
                  onInputChange={(event, newInputValue) => {
                    if (event?.type) {
                      setVideoCategoryDef(newInputValue);
                    }
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getVideoCategoryData(newInputValue);
                    }, 1000);
                    // if (newInputValue.length === 0) {
                    //   setLeadSource(0);
                    // }
                  }}
                  classes={{ inputRoot: styles.padding }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Category"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "0px !important",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                      onKeyDown={(e) => {
                        blockCharInAutocompleteField(e);
                      }}
                    />
                  )}
                />
              </Grid>
              {/* {showTextField ? (
                <div style={{ marginTop: "5px" }}>
                  <HighlightOffIcon
                    style={{
                      marginTop: "5px",
                      marginBottom: "1rem",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                    onClick={handleRemoveClick}
                  />
                </div>
              ) : (
                <AddCircleIcon
                  style={{
                    marginTop: "5px",
                    marginBottom: "1rem",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                  }}
                  onClick={handleAddClick}
                />
              )} */}
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ marginTop: "-10px" }}>
                  <sup
                    style={{
                      fontSize: "11px",
                      marginLeft: "15px",
                      // marginTop: "15px",
                      background: "#fff",
                      color: "rgba(0, 0, 0, 0.54)",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Thumbnail upload
                  </sup>
                  <input
                    className="input"
                    style={{
                      width: "100%",
                      marginTop: "-15px",
                      padding: "10.5px 14px",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "5px",
                    }}
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    onChange={fileSelectedHandlerImg}
                  />
                </div>
                {/* <sub style={{color: "red"}}> (thumbnail resolution must be 1749 X 2481 pixels)</sub> */}
              </Grid>
              <Grid item xs={12} sm={2} md={1} style={{ marginTop: "10px" }}>
                <InfoIcon color="primary" onClick={handleOpenInfo} />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div style={{ marginLeft: "40px" }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    defaultValue={editVideoDetails?.is_active}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={SubmitVideoData}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/video_list")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={modalStatus}
              onClose={closeModal}
              aria-labelledby="form-dialog-title"
              className={classes.dialog}
            >
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <p>Update Video Category</p>
                    <TextField
                      name="categoryName"
                      type="text"
                      value={videoCategoryName}
                      className="input"
                      variant="outlined"
                      onChange={(e) => setVideoCategoryName(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={updateModel} color="primary">
                  Save
                </Button>
                <Button onClick={closeModal} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openInfo}
              onClose={handleCloseInfo}
              closeAfterTransition
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Fade in={openInfo}>
                <div
                  style={{
                    position: "absolute",
                    minHeight: "400",
                    minWidth: "500",
                    backgroundColor: "white",
                  }}
                >
                  <Box>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <div
                        style={{
                          margin: "0rem 2rem",
                          paddingTop: "1rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>Note:</h4>
                        <CloseSharpIcon onClick={handleCloseInfo} />
                      </div>
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 1 }}
                    >
                      <div style={{ margin: "1rem" }}>
                        <div
                          style={{
                            alignItems: "left",
                            display: "inline-block",
                            margin: "1rem",
                          }}
                        >
                          <small>
                            <ul style={{ paddingLeft: "15px" }}>
                              <li
                                style={{
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                The thumbnail resolution must be 1749 X 2481
                                pixels.
                              </li>
                            </ul>
                          </small>
                        </div>
                      </div>
                    </Typography>
                  </Box>
                </div>
              </Fade>
            </Modal>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  videCategoryData: state.MedicoappAdminReducer.videCategoryData,
});

export default connect(mapStateToProps, {
  updatedVideo,
  getVideoCategoryData,
  updatedVideoCategory,
})(CommentsModal2);
