import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader2/index";
import { getAboutUsList,updatedAboutUs } from "../actions/MedicoappAdminActions";
import VisualAidCategoryModel from "../components/CreateData/aboutUsModal";
import EditAboutUsModel from "../components/EditData/updateAboutUsModal";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import EditIcon from '@mui/icons-material/Edit';
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  aboutUsList: any;
  getAboutUsList: any;
  data: any;
  updatedAboutUs: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  aboutUsList,
  getAboutUsList,
  data,
  updatedAboutUs,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<any>("");
  const [viewImg, setViewImg] = useState<boolean>(false);
  const [editAboutUsDetails, setEditAboutUsDetails] = useState<any>({});
  const [openAboutModal, setOpenAboutModal] = useState(false);
  const [rank, setRank] = useState<any>(
    editAboutUsDetails && editAboutUsDetails?.rank
  );


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = aboutUsList && aboutUsList?.next.split("?")[1];
      getAboutUsList(url ? url : "");
    } else if (newPage < page) {
      let url = aboutUsList && aboutUsList?.previous.split("?")[1];
      getAboutUsList(url ? url : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getAboutUsList();
  }, []);

  const handleViewImg = (val: any) => {
    setImage(val);
    setViewImg(true);
  };
  const handleCloseImg = () => {
    setViewImg(false);
    setImage("");
  };

  const handleEditAbout = (data: any) => {
    setOpenAboutModal(true);
    setEditAboutUsDetails(data);
  };

  const handleUser = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
  
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }

    await updatedAboutUs(formData, editAboutUsDetails && editAboutUsDetails.id);
    history.push("/dashboard/mda/about_us");
  };

  const handleEditRank = (data: any) => {
    setRank(data?.rank); // Set the rank state to the current rank value
    setEditAboutUsDetails(data);
    setOpenAboutModal(false);
  };

  const handleChangeRank = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRank(e.target.value); // Update the rank state when the input value changes
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="medico-page">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>About Us</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
              >
                About Us
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Rank</StyledTableCell>
                <StyledTableCell align="center">File</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {aboutUsList &&
                aboutUsList?.results &&
                aboutUsList?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <div>
                        {editAboutUsDetails === data ? (
                          <TextField
                            className="input"
                            name="lead"
                            type="number"
                            value={rank}
                            variant="outlined"
                            onChange={handleChangeRank}
                            style={{ width: "50%" }}
                          />
                        ) : (
                          data?.rank
                        )}
                        </div>
                        <div>
                        {editAboutUsDetails === data ? (
                          <Button
                            color="primary"
                            variant="text"
                           onClick={(e) => handleUser(e)} 
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                           <EditIcon/>
                          </Button>
                        )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.file?.includes("pdf") ? (
                          <a
                            href={data?.file}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              width: "1749px",
                              height: "2481px",
                              // display: "block",
                            }}
                          >
                         <InsertPhotoIcon/>
                          </a>
                        ) : (
                          <InsertPhotoIcon
                            onClick={() => handleViewImg(data?.file)}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.updated_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.is_active === true ? "Active" : "Inactive"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleEditAbout(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={aboutUsList?.count || 0}
                  rowsPerPage={aboutUsList.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        onClose={handleCloseImg}
        aria-labelledby="customized-dialog-title"
        open={viewImg}
        maxWidth={false}
        PaperProps={{
          style: {
            width: "1749px",
            height: "2481px",
            overflow: "hidden",
          },
        }}
      >
        {/* <DialogTitle id="customized-dialog-title">Image</DialogTitle> */}
        <DialogContent>
          <img
            src={image}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </DialogContent>
      </Dialog>
      <VisualAidCategoryModel open={open} setOpen={setOpen} />
      <EditAboutUsModel
        loading={loading}
        editAboutUsDetails={editAboutUsDetails}
        setOpenAboutModal={setOpenAboutModal}
        openAboutModal={openAboutModal}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  aboutUsList: state.MedicoappAdminReducer.aboutUsList,
});

export default connect(mapStateToProps, {
  getAboutUsList,
  updatedAboutUs,
})(CustomUploader);
