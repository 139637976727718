import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import {
  createVisualAid,
  getCategoryList,
  createVisualAidCategory,
} from "../../actions/MedicoappAdminActions";
import {
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import styles from "./style.module.css";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
  input: {
    height: 40,
  },
  dialog: {
    zIndex: 1,
  },
}));

type ModalProps = {
  loading: any;
  open: boolean;
  setOpen: Function;
  createVisualAid: any;
  getCategoryList: any;
  categoryList: any;
  createVisualAidCategory: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  open,
  setOpen,
  createVisualAid,
  getCategoryList,
  categoryList,
  createVisualAidCategory,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [visualAidName, setVisualAidName] = useState<any>("");
  const [isactive, setIsActive] = useState<boolean>(false);
  const [rank, setRank] = useState<any>("");
  const [image, setImage] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [category, setCategory] = useState<any>([]);
  const [showTextField, setShowTextField] = useState(false);
  const [visualAidCatName, setVisualAidCatName] = useState("");
  const [images, setImages] = useState<any>(null);
  const [imageCate, setImageCate] = useState<any>(null);
  const [rankCate, setRankCate] = useState<any>("");
  const [openInfo, setOpenInfo] = React.useState(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  const handleAddClick = () => {
    setModalStatus(true);
  };

  const handleRemoveClick = () => {
    setModalStatus(false);
    setVisualAidCatName("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CreateVisualAid = async (e: any) => {
    e.preventDefault();
    setButtonDisable(true);
    const formData = new FormData();
    formData.append("name", visualAidName);
    formData.append("rank", rank);
    formData.append("thumbnail", image);
    formData.append("category", category);
    formData.append("is_active", String(isactive));
    formData.append("file", file);

    await createVisualAid(formData);
    history.push("/dashboard/mda/visual_aid");
  };

  const fileSelectedHandlerImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };
  const handleSetFileUpload = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
  };

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-`".includes(e.key) &&
      e.preventDefault()
    );
  };

  const fileSelectedHandlerImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageCate(e.target.files[0]);
    }
  };

  const disableCenter = async (data: any) => {
    openModalFun(data);
  };

  const [modalStatus, setModalStatus] = useState<boolean>(false);

  const openModalFun = (val: any) => {
    setVisualAidName("");
    setModalStatus(true);
  };

  const closeModal = () => {
    setVisualAidName("");
    setModalStatus(false);
  };

  const submitModel = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", visualAidCatName);
    formData.append("thumbnail", imageCate);
    formData.append("rank", rankCate);

    await createVisualAidCategory(formData);
    getCategoryList();
    closeModal();
  };

  useEffect(() => {
    if (buttonDisable) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setButtonDisable(false);
      }, 20000);
    }
  }, [buttonDisable]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <form onSubmit={CreateVisualAid}>
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Create Visual Aid
                </h4>
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="visualAidName"
                    type="text"
                    value={visualAidName}
                    className="input"
                    label="Visual Aid Name"
                    variant="outlined"
                    onChange={(e: any) =>
                      setVisualAidName(e.target.value as string)
                    }
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    name="lead"
                    type="number"
                    label="Rank"
                    value={rank}
                    variant="outlined"
                    onChange={(e) => setRank(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="category"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj.id);
                        });
                        setCategory(packs);
                      }
                    }}
                    options={categoryList || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    loading={loading}
                    multiple
                    getOptionLabel={(option: any) => option && option?.name}
                    getOptionDisabled={(option: any) => {
                      return category.includes(option.id);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getCategoryList(`search=${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setCategory([]);
                      }
                    }}
                    classes={{ inputRoot: styles.padding }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Category"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0px !important",
                        }}
                        required={category?.length > 0 ? false : true}
                        // InputProps={{
                        //   className: classes.input,
                        // }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                {showTextField ? (
                  <div style={{ marginTop: "5px" }}>
                    <HighlightOffIcon
                      style={{
                        marginTop: "5px",
                        marginBottom: "1rem",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      onClick={handleRemoveClick}
                    />
                  </div>
                ) : (
                  <AddCircleIcon
                    style={{
                      marginTop: "5px",
                      marginBottom: "1rem",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                    onClick={handleAddClick}
                  />
                )}
                <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginTop: "-10px" }}>
                    <sup
                      style={{
                        fontSize: "11px",
                        marginLeft: "15px",
                        // marginTop: "15px",
                        background: "#fff",
                        color: "rgba(0, 0, 0, 0.54)",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      MP4 video upload
                    </sup>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        marginTop: "-15px",
                        padding: "10.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={handleSetFileUpload}
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginTop: "-10px" }}>
                    <sup
                      style={{
                        fontSize: "11px",
                        marginLeft: "15px",
                        // marginTop: "15px",
                        background: "#fff",
                        color: "rgba(0, 0, 0, 0.54)",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Thumbnail upload
                    </sup>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        marginTop: "-15px",
                        padding: "10.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="image"
                      id="image"
                      accept="image/jpeg"
                      onChange={fileSelectedHandlerImg}
                      required
                    />
                  </div>
                  {/* <sub style={{ color: "red" }}>
                    {" "}
                    (thumbnail resolution must be 1749 X 2481 pixels)
                  </sub> */}
                </Grid>
                <Grid item xs={12} sm={2} md={1} style={{ marginTop: "10px" }}>
                  <InfoIcon color="primary" onClick={handleOpenInfo} />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <div style={{ marginLeft: "40px" }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={isactive}
                      label="IsActive"
                      onChange={(e) => setIsActive(!isactive)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{ width: "100%" }}
                    // onClick={CreateVisualAid}
                    type="submit"
                    disabled={buttonDisable}
                  >
                    ADD{loading && <CircularProgress size={24} />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/mda/visual_aid_category")
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={modalStatus}
                onClose={closeModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
              >
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <p>Add New Visual Aid Category</p>
                      <TextField
                        name="categoryName"
                        type="text"
                        label="Visual Aid Category Name"
                        value={visualAidCatName}
                        className="input"
                        variant="outlined"
                        onChange={(e) => setVisualAidCatName(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                   <Grid item xs={12} sm={6}>
                      <TextField
                        className="input"
                        name="rank"
                        type="number"
                        label="Rank"
                        value={rankCate}
                        variant="outlined"
                        onChange={(e) => setRankCate(e.target.value)}
                        style={{ width: "100%", marginTop: "35px" }}
                        required
                      />
                    </Grid>
                   <Grid item xs={12} sm={6}>
                      <div style={{ marginTop: "25px" }}>
                        <sup
                          style={{
                            fontSize: "11px",
                            marginLeft: "15px",
                            // marginTop: "15px",
                            background: "#fff",
                            color: "rgba(0, 0, 0, 0.54)",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Thumbnail upload
                        </sup>
                        <input
                          className="input"
                          style={{
                            width: "100%",
                            marginTop: "-15px",
                            padding: "10.5px 14px",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "5px",
                          }}
                          type="file"
                          name="image"
                          id="image"
                          accept="image/jpeg"
                          onChange={fileSelectedHandlerImage}
                          required
                        />
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={submitModel} color="primary">
                    Save
                  </Button>
                  <Button onClick={closeModal} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openInfo}
                onClose={handleCloseInfo}
                closeAfterTransition
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Fade in={openInfo}>
                  <div
                    style={{
                      position: "absolute",
                      minHeight: "400",
                      minWidth: "500",
                      backgroundColor: "white",
                    }}
                  >
                    <Box>
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <div
                          style={{
                            margin: "0rem 2rem",
                            paddingTop: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4>Note:</h4>
                          <CloseSharpIcon onClick={handleCloseInfo} />
                        </div>
                      </Typography>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 1 }}
                      >
                        <div style={{ margin: "1rem" }}>
                          <div
                            style={{
                              alignItems: "left",
                              display: "inline-block",
                              margin: "1rem",
                            }}
                          >
                            <small>
                              <ul style={{ paddingLeft: "15px" }}>
                                <li
                                  style={{
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  The thumbnail resolution must be 1749 X 2481
                                  pixels.
                                </li>
                              </ul>
                            </small>
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </div>
                </Fade>
              </Modal>
            </div>
          </Fade>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  categoryList: state.MedicoappAdminReducer.categoryList,
});

export default connect(mapStateToProps, {
  createVisualAid,
  getCategoryList,
  createVisualAidCategory,
})(CommentsModal2);
