import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { updatedAdminCity,getCityData } from "../../actions/MedicoappAdminActions";
import { TextField, Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { Autocomplete } from "@mui/material";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
}));

type ModalProps = {
  cityLoading: any;
  editCityDetails: any;
  openCityModal: boolean;
  setOpenCityModal: Function;
  updatedAdminCity: any;
  cityData: any;
  getCityData: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  cityLoading,
  editCityDetails,
  openCityModal,
  setOpenCityModal,
  updatedAdminCity,
  cityData,
  getCityData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [city, setCity] = useState<any>([]);
  const [isactive, setIsActive] = useState<boolean>(editCityDetails && editCityDetails?.is_active);
  const [rank, setRank] = useState<any>(editCityDetails && editCityDetails?.rank);

  const handleClose = () => {
    setOpenCityModal(false);
  };

  const CreateLblCategory = async () => {
    const body: any = {
      name: city,
      is_active: isactive,
      rank: rank,
    };
    if (city === "" || city === "none") {
      delete body.name;
    }
    if (String(isactive) === "" || String(isactive) === "none") {
      delete body.is_active;
    }
    if (rank === "" || rank === "none") {
      delete body.rank;
    }
    await updatedAdminCity(body,editCityDetails && editCityDetails?.id);
    history.push("/dashboard/mda/city");
   
  };

  useEffect(() => {
    setCity(editCityDetails?.city);
    setRank(editCityDetails?.rank);
    setIsActive(editCityDetails?.is_active);
  }, [editCityDetails]);

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-`".includes(e.key) &&
      e.preventDefault()
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCityModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openCityModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Update City
              </h4>
              <CloseIcon onClick={() => setOpenCityModal(false)} />
            </div>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                  id="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCity(obj?.city);
                    }
                  }}
                  options={cityData || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.name}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getCityData(`search=${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setCity("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="City"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                      onKeyDown={(e) => {
                        blockCharInAutocompleteField(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  label="Rank"
                  value={rank}
                  variant="outlined"
                  onChange={(e) => setRank(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ marginLeft: "80px" }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={CreateLblCategory}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/city")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  cityData: state.MedicoappAdminReducer.cityData,
});

export default connect(mapStateToProps, {
  updatedAdminCity,
  getCityData
})(CommentsModal2);
