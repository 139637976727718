import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";
import { Link, useLocation } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  alpha,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {
  searchCall,
  logout,
  panelUserLogout,
  getFirebaseNotification,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import {
  changePassword,
  getUserDetails,
  getNotificationMessage,
} from "../actions/MedicoappAdminActions";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Popover from "@material-ui/core/Popover";
import { useHistory } from "react-router";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import { getToken, onMessageListener } from "../../firebase";
import NewUpdateModal from "../components/Comments/newUpdate";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import CategoryIcon from "@mui/icons-material/Category";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import YouTubeIcon from "@mui/icons-material/YouTube";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GrassIcon from "@mui/icons-material/Grass";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from '@mui/icons-material/Equalizer';
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);
interface Props {
  getUserDetails: any;
  userDetails: any;
  getNotificationMessage: any;
  notificationMessage: any;
  passwordStatus: boolean;
}
const TechSupport: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  userDetails,
  notificationMessage,
  passwordStatus,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [heights, setheights] = useState(window.innerHeight);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const audio = new Audio(
    "https://static.redcliffelabs.com/media/mixkit-modern-classic-door-bell-sound-113.mp3"
  );
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;
  const [value, setValue] = React.useState<string>("");
  const [option, setOption] = React.useState<string>("call_id");
  useEffect(() => {
    let token;

    async function tokenFunc() {
      token = await getToken(setTokenFound);
      if (token) {
        const body = {
          token,
        };
        dispatch(getFirebaseNotification(body));
      }
      return token;
    }

    tokenFunc();
  }, [setTokenFound]);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const submitForm = (e: any) => {
    e.preventDefault();
    if (value.trim().length > 0) {
      let body = {
        search_by: option,
        value,
      };
      dispatch(searchCall(body));
      history.push("/dashboard/mda/lead/search");
    }
  };

  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const inputValue = event.target.value as string;
    setValue(inputValue.trimStart());
  };

  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleNotification = () => {
    history.push("/dashboard/mda/inbox");
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  useEffect(() => {
    if (notification.body !== "") {
      setOpenSnack(true);
      audio.play();
    }
  }, [notification]);
  const handleSnackClose = () => {
    setOpenSnack(false);
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  const location = useLocation();
  return (
    <div className={classes.root}>
      <Snackbar
        // anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleSnackClose}
        message={notification.body}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={submitForm}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={value}
                onChange={handleValueChange}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div> */}
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          {/* <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleNotification}
            color="inherit"
          >
            <NotificationsActiveIcon />
          </IconButton> */}
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          // onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button onClick={() => setchangePasswordModal(false)} color="primary">
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {/* <Link to="/dashboard/mda">
            <ListItem
              button
              selected={location.pathname === "/dashboard/mda" ? true : false}
            >
              <Tooltip title="Bookings" arrow>
                <ListItemIcon>
                  <Book style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Bookings" />
            </ListItem>
          </Link> */}
          {/* <Link to="/dashboard/mda/CityAlias-Master">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/CityAlias-Master"
                  ? true
                  : false
              }
            >
              <Tooltip title="Manage City Alias" arrow>
                <ListItemIcon>
                  <ElectricCarIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage City Alias" />
            </ListItem>
          </Link> */}
          {/* <Link to="/dashboard/mda/alias">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/alias" ? true : false
              }
            >
              <Tooltip title="Manage package Alias" arrow>
                <ListItemIcon>
                  <CreateNewFolderIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Package Alias" />
            </ListItem>
          </Link> */}
          <Link to="/dashboard/mda/lbl">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/lbl" ? true : false
              }
            >
              <Tooltip title="LBL" arrow>
                <ListItemIcon>
                  <EqualizerIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="LBL" />
            </ListItem>
          </Link>
          <Link to="/dashboard/mda/lbl_category_list">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/lbl_category_list"
                  ? true
                  : false
              }
            >
              <Tooltip title="LBL Category" arrow>
                <ListItemIcon>
                  <CategoryIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="LBL Category" />
            </ListItem>
          </Link>
          <Link to="/dashboard/mda/lbl_faqs">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/lbl_faqs"
                  ? true
                  : false
              }
            >
              <Tooltip title="LBL FAQs" arrow>
                <ListItemIcon>
                  <QuestionAnswerIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="LBL FAQs" />
            </ListItem>
          </Link>
          <Link to="/dashboard/mda/video_list">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/video_list" ? true : false
              }
            >
              <Tooltip title="Video" arrow>
                <ListItemIcon>
                  <SlideshowIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Video" />
            </ListItem>
          </Link>
          {/* <Link to="/dashboard/mda/video_category_list">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/video_category_list"
                  ? true
                  : false
              }
            >
              <Tooltip title="Video Category" arrow>
                <ListItemIcon>
                  <YouTubeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Video Category" />
            </ListItem>
          </Link> */}

          <Link to="/dashboard/mda/visual_aid">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/visual_aid" ? true : false
              }
            >
              <Tooltip title="Visual Aid" arrow>
                <ListItemIcon>
                  <AddTaskIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Visual Aid" />
            </ListItem>
          </Link>
          <Link to="/dashboard/mda/visual_aid_category">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/visual_aid_category"
                  ? true
                  : false
              }
            >
              <Tooltip title="Visual Aid Category" arrow>
                <ListItemIcon>
                  <GrassIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Visual Aid Category" />
            </ListItem>
          </Link>

          <Link to="/dashboard/mda/city">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/city" ? true : false
              }
            >
              <Tooltip title="City" arrow>
                <ListItemIcon>
                  <LocationCityIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="City" />
            </ListItem>
          </Link>
          <Link to="/dashboard/mda/about_us">
            <ListItem
              button
              selected={
                location.pathname === "/dashboard/mda/about_us" ? true : false
              }
            >
              <Tooltip title="About Us" arrow>
                <ListItemIcon>
                  <InfoIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="About Us" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {notificationMessage &&
      notificationMessage?.results &&
      notificationMessage?.results?.length > 0
        ? notificationMessage?.results.map((data: any, index: any) => {
            return (
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>{index + 1 + "." + data?.notification}</Typography>
              </Popover>
            );
          })
        : null}
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.MedicoappAdminReducer.userDetails,
  notificationMessage: state.MedicoappAdminReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {
  getUserDetails,
  getNotificationMessage,
})(TechSupport);
