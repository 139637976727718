export const ACTIONS = {
  GET_LBL_CATEGORY_LIST: "md/get_lbl_category_list",
  GET_LBL_FAQS: "md/get_lbl_faqs",
  CREATE_LBL_CATEGORY: "md/create_lbl_category",
  GET_VIDEO_CATEGORY_LIST: "md/get_video_category_list",
  CREATE_VIDEO_CATEGORY: "md/create_video_category",
  GET_VIDEO_LIST: "md/get_video_list",
  CREATE_VIDEO: "md/create_video",
  GET_VISUAL_AID_CATEGORY_LIST: "md/get_visual_aid_category_list",
  CREATE_VISUAL_AID_CATEGORY: "md/create_visual_aid_category",
  GET_VISUAL_AID_LIST: "md/get_visual_aid_list",
  CREATE_VISUAL_AID: "md/create_visual_aid",
  GET_ADMIN_CITY_LIST: "md/get_admin_city_list",
  GET_CITY_DATA: "md/get_city_data",
  CREATE_ADMIN_CITY: "md/create_admin_city",
  GET_ABOUT_US_LIST: "md/get_about_us_list",
  GET_LBL_FAQS_DATA_LIST: "md/get_lbl_faqs_data_list",
  CREATE_ABOUT_US: "md/create_about_us",
  GET_LBL_LIST: "md/get_lbl_list",
  GET_LBL_MORE_INFO_LIST: "md/get_lbl_more_info_list",
  GET_CATEGORY_LIST: "md/get_category_list",
  GET_LBL_CATEGORY_DATA: "md/get_lbl_category_data",
  GET_LBL_FAQS_DATA: "md/get_lbl_faqs_data",
  GET_VIDEO_CATEGORY_DATA: "md/get_video_category_data",
  GET_PACKAGE_EDOS: "md/get_package_edos",
  CREATE_LBL: "md/create_lbl",
  CREATE_FAQS: "md/create_faqs",
  DELETE_FAQS: "md/delete_faqs",
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "md/get_pin_code",
  GET_CITIES: "md/get_cities",
  GET_AREA: "md/get_area",
  GET_AGENTS: "md/get_agents",
  GET_AGENTS_BY_USERGROUP: "md/get_agents_by_usergroup",
  GET_PACKAGES: "md/get_packages",
  GET_PHLEBOS: "md/get_phlebos",
  GET_PHLEBOS_DATA: "md/get_phlebos_data",
  GET_PARTNERS: "md/get_partners",
  GET_PARTNERS_AUTO: "md/get_partners_auto",
  GET_COUPONS: "md/get_coupons",
  CREATE_BOOKING: "md/create_booking",
  UPDATE_BOOKING: "md/update_booking",
  GET_BOOKING_LIST: "md/get_booking_list",
  GET_LEAD_DETAILS: "md/get_lead_details",
  GET_LEAD_BY_ID: "md/get_lead_by_id",
  LEAD_DETAILS_RESET: "md/lead_details_reset",
  GET_LEADS: "md/get_leads",
  GET_BOOKING_BY_ID: "md/get_booking_by_id",
  CLICK_TO_CALL: "md/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "md/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "md/add_comments",
  SET_COMMENTS: "md/set_commnets",
  UPLOAD_FILE: "md/upload",
  SET_LOADING: "ts_booking/set_loading",
  SET_AREA_LOADING: "ts_booking/set_area_loading",
  SEARCH_CALL: "md/serach",
  BOOKING_RESET: "md/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "md/createcall",
  GET_LEAD_SOURCE: "md/getleadsource",
  GET_RECORDINGS: "md/get_recordings",
  GET_RECORDINGS_BACKUP: "md/get_recordings_backup",
  GET_BOOKINGS_COMMENTS: "md/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "md/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "md/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "md/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "md/send",
  GET_CALL_STATS: "md/get_call_stats",
  RAISE_COMPLAINT: "md/raise_complaint",
  GET_COMPLAINTS: "md/get_complaints",
  ASSIGN_DOCTOR: "md/assign_doctor",
  SEND_FEEDBACK: "md/send_feedback",
  GET_AVAILABLE_SLOTS: "md/get_available_slots",
  GET_ALL_LEADS: "md/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "md/set_comments",
  GET_PANEL_USERS: "md/get_panel_user",
  ADD_REMARK_ON_CALL: "md/add_remark_on_call",
  GET_BREAK_DATA: "md/get_break_data",
  UPDATE_COUPON: "md/update_coupon",
  CREATE_COUPON: "md/create_coupon",
  GET_VERIFICATION_BOOKING_DETAILS: "md/get_verification_booking_details",
  GET_UNMASKED_NUMBER: "md/get_unmasked_number",
  CREATE_ADDITIONAL_BOOKING: "md/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "md/update_additional_booking",
  LEAD_TRANSFER_TO_TATATALE: "md/lead_transfer_to_tatatale",
  GET_LEAD_STATUS: "md/get_lead_status",
  GET_PRESCRIPTION_DATA: "md/get_prescription_data",
  CHECK_GEOFENCE_AREA: "md/checking-geofence-area",
  GET_RED_COIN: "md/get_red_coin",
  GET_ADDRESS_TYPE_SEATING: "qc/get_address_type_seating",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "qc/checking-geofence-area-mapmyindia",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "qc/get-mapmyindia-access-token",
  GET_USER_DETAILS: "qc/get_user_details",
  GET_NOTIFICATION_MESSAGE: "qc/get_notification_message",
  GET_TICKET_DATA: "qc/get_ticket_data",
  GET_TICKET_CATEGORIES: "md/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "md/get_ticket_categories",
  CREATE_TASK_TICKET: "md/create_task_ticket",
  GET_RED_TECH_COMMENT: "md/redtech_comments/",
  POST_RED_TECH_COMMENT: "md/postredtech_comments/",
  GET_QUALITY_SCORING_REPORT: "md/get_quality_scoring_report",
  GET_CALLS_DATA: "md/get_calls_data",
  POST_QUALITY_SCORE: "md/post_quality_scoring",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
    "md/get_quality_scoring_report_for_backup",
  GET_CENTER_INFORMATION: "md/get_center_information",
  SET_USER_LOADING: "md/set_User_loading",
  GET_USERS_LIST: "md/get_user_list",
  GET_PANEL_COMMENT: "md/get_panel_comments",
  GET_PANEL_USER_LIST_DATA: "md/get_panel_user_list_data",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD: "md/get_panel_user_list_data_sales_lead",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "md/get_panel_user_list_data_team_lead",
  CREATE_PANEL_USER: "md/create_panel_user",
  UPDATED_PANEL_USER: "md/updated_panel_user",
  UPDATE_TICKET_STATUS: "md/update_ticekt_status",
  UPDATE_USERS_PASSWORD: "md/update_users_password",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "md/cstech_create-ticekt",
  DISABLED_USER_DETAILS: "md/auth/user_details",
  GET_USER_LIST_DATA: "md/auth/user_data",
  GET_OZONTEL_SKILL_DATA: "md/get_ozontel_skill_data",
  UPDATE_PHLEBOS: "md/update_phlebos",
  GET_PHLEBO_COMMENTS: "md/get_phlebo_commnets",
  GET_ZONE: "md/get_zone",
  CREATE_NEW_PHLEBO: "md/create_phlebo",
  GET_PHLEBO_BY_PHONE: "md/get_phlebo_by_phone",
  GET_PAYMENT_INFORMATION: "md/get_payment_information",
  GET_RIDER_LIST: "md/get_rider_list",
  UPDATE_RIDER_TO_LAB_STATUS: "md/update_rider_to_lab_status",
  GET_SAMPLE_HANDOVER_DATA: "md/getsample_handover_data",
  GET_SAMPLE_JOURNEY_COMMENTS: "md/get_sample_journey_comments",
  GET_BATCH_BOOKING_AMOUNT: "md/get_batch_amount",
  UPDATE_RIDER_SAMPLE_HANDOVER: "md/update_rider_sample_handover",
  GET_TICKET_COMMENTS: "md/ticket_comments",
  GET_TICKET_COMMENTS_FOR_DOCUMENTS: "md/ticket_comments_for_documents",
  SET_NEW_TICKET_LOADING: "md/ticket_loading",
  GET_BOOKING_COMPLAINTS: "md/get_booking_complaints",
  UPDATE_DISPOSITION: "md/update_disposition",
  GET_LANGUAGE: "md/get_language",
  GET_DIALER: "md/get_dialer",
  GET_ORG_TYPES: "md/get_org_types",
  GET_CHAT_SKILLS_LISTS: "md/get_chat_skills_lists",
  GET_ALL_USERS: "md/get_all_users",
  GET_OTHER_USERS: "md/get_other_users",
  POST_DISPOSITION_DATA: "md/lead/lead-status-update/",
  GET_LEAD_DISPOSITION_LIST: "md/plead/status-category/",
  SET_ALL_BOOKING_LOADING: "md/set_all_booking_loading",
  GET_TIMESLOTS: "md/get_timeslots",
  CREATE_ZONE: "md/create_zone",
  GET_ZONE_COMMENTS: "md/get_zone_comments",
  UPDATE_ZONE: "md/update_zone",
  GET_CAMPAINGS_DETAILS: "md/dialer_settings",

  CREATE_DIALER: "md/create_dialer",
  GET_ASSIGNED_UNASSIGNED_BOOKING: "md/get_assigned_unassigned_bookings",
  SET_ASSIGNED_LOADING: "md/set_assigned_loader",
  GET_MAP_BOOKING_LIST: "md/get_map_booking",
  GET_PHLEBO_CURRENT_LOCATION: "md/phlebo_current_location",
  UPDATED_CITY_ALIAS: "md/city_alias_updated",
  GET_CITY_ALIAS: "md/get_city_alias",
  CREATE_CITY_ALIAS: "md/city_alias_created",
  GET_CITY_COMMENTS: "md/get_city_comments",
  GET_PACKAGE_COMMENTS: "md/get_package_comments",
  SEND_INITIAL_WHATSAPP_MESSAGE: "md/send_initial_whatsapp_message",
  GET_LTV_LEAD: "md/get_ltv_lead",
  UPDATE_IMAGING_TICKET: "md/update_imaging_ticket",
  GET_TOP_RC_FRESH_LEADS: "md/get_top_rc_fresh_leads",
  GET_IMAGING_TICKET: "md/get_imaging_ticket",
  GET_WHATSAPP_TEMPLATE: "md/get_whatsapp_template",
  CREATE_WHATSAPP_TEMPLATE: "md/create_whatsapp_template",
  INITIATE_REFUND: "md/initiate_refund",
  SYNC_BOOKING_WITH_ES: "md/sync_booking_with_es",
  SEND_LOCATION_REQUEST: "md/communication/send-location-request/",
  GET_PAYMENT_SEND_LINK: "md/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "md/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "md/get_payment_cancel_link",
  GET_SMS_REPORT_SEND: "md/get_sms_report_send",
  GET_SMS_DETAILS: "md/get_sms_details",
  GET_EMAIL_DETAILS: "md/get_email_details",
  GET_REPORT_STATUS: "md/get_report_status",
  GET_REPORT_DOWNLOAD: "md/get_report_download",
  SET_REPORT_LOADING: "md/set_report_loading",
  GET_ADDITIONAL_BOOKING: "md/get_additional_booking",
  GET_SIGNATURE: "md/get_signature",
  GET_QRCODE_DATA: "md/get_qrcode_data",
  SET_GEO_CHECK_ERROR: "md/set_geo_check_error",
  SET_GEO_LOADING: "md/set_geo_loading",
  GET_CENTER: "md/get_center",
  CREATE_PARTNER: "md/create_partner",
  GET_USER_DATA: "md/get_users_data",
  GET_PACKAGE_ALIAS: "md/get_package_alias",
  CREATE_PACKAGE_ALIAS: "md/package_alias_created",
  UPDATED_PACKAGE_ALIAS: "md/package_alias_updated",
  GET_AGENTS_NEW_DATA: "md/get_agents_new_data",
  POST_REGENERATE_PAYMENT: "md/post_regenerate_payment",
  CANCEL_TO_CONFIRM: "md/cancel_to_confirm",
  CONFIRM_TO_PENDING: "md/confirm_to_pending",
  GET_BOOKING_SUB_DISPOSITION: "md/ead/lead-status/",
  GET_PANEL_USER_COMMENT: "md/get-panel-user-comment",
  GET_VERIFIED_BY_LIST: "md/get_verified_by_list",
  GET_COLLECTION_SLOT: "md/get_collection_slot",
  GET_PHLEBO_TIMELINE: "md/timeline",
  LOCATION_VERIFIED: "md/location_verified",
  GET_ADDRESS_DETAILS_LAT_LONG: "md/get-address-details-lat-long",
  GET_BOOKING_CALLS: "md/get_booking_calls",
  GET_USER_EDIT_DATA: "md/get_user_edit",
  CREATE_LAB: "md/create_lab",
  UPDATE_LAB: "md/update_employee",
  GET_LAB_CREDENTIAL: "md/get_lab_cred",
  GET_LAB_LOG: "md/get_lablog",
  RESET_LOGS: "md/reset_logs",
  UPDATE_CENTER: "md/update_center",
  UPDATED_DIALER: "md/updated_dialer",
  GET_CENTER_DATA: "md/get_center_Data",
  GET_SUB_CENTER_INFORMATION: "md/get_sub_center_information",
  GET_IMAGING_TIMESLOTS: "md/get_imaging_timeslots",
  GET_DISPLAY_CITIES: "md/get_display_cities",
  GET_MULTIPLE_PACKAGES: "md/get_multiple_package",
  GET_PAGINATION_COMMENT: "md/get_pagination_comment",
  SET_PHLEBOS_LOADING: "ts_booking/set_loading_phlebos",
  GET_PHLEBOS_AUTO: "md/get_phlebos_auto",
  GET_USER_TYPES: "md/get_user_types",
  CREATE_AREA: "md/create_area",
  UPDATE_AREA: "md/update_area",
  GET_AREA_COMMENTS: "md/get_area_comments",
  UPDATE_PARTNER: "md/partner/update/",
  PARTNER_CREATED: "md/partner/create/",
  SET_PARTNER_LOADING: "md/partner/loading/",
  SET_LOADING_CITY: "md/set_loading_city",
  UPDATE_PICK_UP_STATUS: "md/update_pick_up_status",
};

interface GetLblCategoryList {
  type: typeof ACTIONS.GET_LBL_CATEGORY_LIST;
  payload: boolean;
}
interface GetLblFaqs {
  type: typeof ACTIONS.GET_LBL_FAQS;
  payload: boolean;
}
interface GetVideoCategoryList {
  type: typeof ACTIONS.GET_VIDEO_CATEGORY_LIST;
  payload: boolean;
}
interface CreateLblCategory {
  type: typeof ACTIONS.CREATE_LBL_CATEGORY;
  payload: Object;
}
interface CreateVideoCategory {
  type: typeof ACTIONS.CREATE_VIDEO_CATEGORY;
  payload: Object;
}
interface CreateVideo {
  type: typeof ACTIONS.CREATE_VIDEO;
  payload: Object;
}
interface GetVideoList {
  type: typeof ACTIONS.GET_VIDEO_LIST;
  payload: boolean;
}
interface GetVisualAidCategoryList {
  type: typeof ACTIONS.GET_VISUAL_AID_CATEGORY_LIST;
  payload: boolean;
}
interface CreateVisualAidCategory {
  type: typeof ACTIONS.CREATE_VISUAL_AID_CATEGORY;
  payload: Object;
}
interface GetVisualAidList {
  type: typeof ACTIONS.GET_VISUAL_AID_LIST;
  payload: boolean;
}
interface CreateVisualAid {
  type: typeof ACTIONS.CREATE_VISUAL_AID;
  payload: Object;
}
interface GetAdminCityList {
  type: typeof ACTIONS.GET_ADMIN_CITY_LIST;
  payload: boolean;
}
interface GetCityData {
  type: typeof ACTIONS.GET_CITY_DATA;
  payload: boolean;
}
interface CreateAdminCity {
  type: typeof ACTIONS.CREATE_ADMIN_CITY;
  payload: Object;
}
interface GetAboutUsList {
  type: typeof ACTIONS.GET_ABOUT_US_LIST;
  payload: boolean;
}
interface GetLblFaqsDataList {
  type: typeof ACTIONS.GET_LBL_FAQS_DATA_LIST;
  payload: boolean;
}
interface CreateAboutUs {
  type: typeof ACTIONS.CREATE_ABOUT_US;
  payload: Object;
}
interface UpdatedPanelUser {
  type: typeof ACTIONS.UPDATED_PANEL_USER;
  payload: Object;
}
interface GetUserEdit {
  type: typeof ACTIONS.GET_USER_EDIT_DATA;
  payload: Array<any>;
}
interface GetLblList {
  type: typeof ACTIONS.GET_LBL_LIST;
  payload: boolean;
}
interface GetPackageEdos {
  type: typeof ACTIONS.GET_PACKAGE_EDOS;
  payload: boolean;
}
interface CreateLbl {
  type: typeof ACTIONS.CREATE_LBL;
  payload: Object;
}
interface CreateFaqs {
  type: typeof ACTIONS.CREATE_FAQS;
  payload: Object;
}
interface DeleteFaqs {
  type: typeof ACTIONS.DELETE_FAQS;
  payload: Object;
}
interface GetLblMoreInfoList {
  type: typeof ACTIONS.GET_LBL_MORE_INFO_LIST;
  payload: boolean;
}
interface GetCategoryList {
  type: typeof ACTIONS.GET_CATEGORY_LIST;
  payload: boolean;
}
interface GetLblCategoryData {
  type: typeof ACTIONS.GET_LBL_CATEGORY_DATA;
  payload: boolean;
}
interface GetLblFaqsData {
  type: typeof ACTIONS.GET_LBL_FAQS_DATA;
  payload: boolean;
}
interface GetCategoryData {
  type: typeof ACTIONS.GET_VIDEO_CATEGORY_DATA;
  payload: boolean;
}
interface BookingConfirmtoPending {
  type: typeof ACTIONS.CONFIRM_TO_PENDING;
  payload: Array<any>;
}
interface BookingConfirmtoCancel {
  type: typeof ACTIONS.CANCEL_TO_CONFIRM;
  payload: Array<any>;
}
interface PostRegeneratePyament {
  type: typeof ACTIONS.POST_REGENERATE_PAYMENT;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface UpdateUsersPassword {
  type: typeof ACTIONS.UPDATE_USERS_PASSWORD;
  payload: Array<any>;
}
interface CreatePanelUser {
  type: typeof ACTIONS.CREATE_PANEL_USER;
  payload: Object;
}
interface GetPanelComments {
  type: typeof ACTIONS.GET_PANEL_COMMENT;
  payload: Array<any>;
}
interface GetPanelUserList {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
  payload: Array<any>;
}

interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}

interface GetRecordings {
  type: typeof ACTIONS.GET_RECORDINGS;
  payload: Object;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetRecordingsBackups {
  type: typeof ACTIONS.GET_RECORDINGS_BACKUP;
  payload: Object;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}


interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetRedCoin {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}


interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetPhleboData {
  type: typeof ACTIONS.GET_PHLEBOS_DATA;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}
interface GetPartnersAuto {
  type: typeof ACTIONS.GET_PARTNERS_AUTO;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetAreaLoading {
  type: typeof ACTIONS.SET_AREA_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface DisabledUserDetails {
  type: typeof ACTIONS.DISABLED_USER_DETAILS;
  payload: Object;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Array<any>;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}
interface GetCallsData {
  type: typeof ACTIONS.GET_CALLS_DATA;
  payload: Array<any>;
}
interface PostQualityScore {
  type: typeof ACTIONS.POST_QUALITY_SCORE;
  payload: Array<any>;
}
interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}
interface GetUserData {
  type: typeof ACTIONS.GET_USER_LIST_DATA;
  payload: Array<any>;
}
interface UpdateUsersPassword {
  type: typeof ACTIONS.UPDATE_USERS_PASSWORD;
  payload: Array<any>;
}
interface getOzontelSkillData {
  type: typeof ACTIONS.GET_OZONTEL_SKILL_DATA;
  payload: Object;
}
interface SetUserLoading {
  type: typeof ACTIONS.SET_USER_LOADING;
  payload: boolean;
}
interface UpdatePhlebos {
  type: typeof ACTIONS.UPDATE_PHLEBOS;
  payload: Object;
}
interface GetPhleboComment {
  type: typeof ACTIONS.GET_PHLEBO_COMMENTS;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreatePhlebo {
  type: typeof ACTIONS.CREATE_NEW_PHLEBO;
  payload: boolean;
}
interface GetPhleboByPhone {
  type: typeof ACTIONS.GET_PHLEBO_BY_PHONE;
  payload: Object;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface UpdateRiderSampleHandover {
  type: typeof ACTIONS.UPDATE_RIDER_SAMPLE_HANDOVER;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetTicketCommentsForDocuments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS;
  payload: Array<any>;
}
interface SetNewTicktingLoading {
  type: typeof ACTIONS.SET_NEW_TICKET_LOADING;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetLanguage {
  type: typeof ACTIONS.GET_LANGUAGE;
  payload: boolean;
}
interface GetDialer {
  type: typeof ACTIONS.GET_DIALER;
  payload: boolean;
}
interface GetOrgTypes {
  type: typeof ACTIONS.GET_ORG_TYPES;
  payload: boolean;
}
interface GetChatSkillsList {
  type: typeof ACTIONS.GET_CHAT_SKILLS_LISTS;
  payload: boolean;
}
interface GetAllUsers {
  type: typeof ACTIONS.GET_ALL_USERS;
  payload: boolean;
}
interface GetOtherUsers {
  type: typeof ACTIONS.GET_OTHER_USERS;
  payload: boolean;
}
interface PostDispositionData {
  type: typeof ACTIONS.POST_DISPOSITION_DATA;
  payload: Array<any>;
}
interface GetLeadDispostion {
  type: typeof ACTIONS.GET_LEAD_DISPOSITION_LIST;
  payload: Object;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetCampaignsDetails {
  type: typeof ACTIONS.GET_CAMPAINGS_DETAILS;
  payload: boolean;
}
interface createDialer {
  type: typeof ACTIONS.CREATE_DIALER;
  payload: Object;
}
interface GetAssignedUnassignedBooking {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING;
  payload: Array<any>;
}
interface SetAssignedUnassignedLoader {
  type: typeof ACTIONS.SET_ASSIGNED_LOADING;
  payload: Array<any>;
}
interface GetMapBooking {
  type: typeof ACTIONS.GET_MAP_BOOKING_LIST;
  payload: Array<any>;
}
interface GetPhleboCurrentLocation {
  type: typeof ACTIONS.GET_PHLEBO_CURRENT_LOCATION;
  payload: Array<any>;
}
interface GetCityAlias {
  type: typeof ACTIONS.GET_CITY_ALIAS;
  payload: Array<any>;
}
interface CreateCityAlias {
  type: typeof ACTIONS.CREATE_CITY_ALIAS;
  payload: Array<any>;
}
interface GetCityComments {
  type: typeof ACTIONS.GET_CITY_COMMENTS;
  payload: Object;
}
interface GetPackageComments {
  type: typeof ACTIONS.GET_PACKAGE_COMMENTS;
  payload: Object;
}
interface SendInititalWhatsapp {
  type: typeof ACTIONS.SEND_INITIAL_WHATSAPP_MESSAGE;
  payload: Array<any>;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface UpdateImagingTicket {
  type: typeof ACTIONS.UPDATE_IMAGING_TICKET;
  payload: Array<any>;
}
interface GetTopRcFreshLeads {
  type: typeof ACTIONS.GET_TOP_RC_FRESH_LEADS;
  payload: Array<any>;
}
interface GetImagingTicket {
  type: typeof ACTIONS.GET_IMAGING_TICKET;
  payload: Object;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface SyncBookingWithEs {
  type: typeof ACTIONS.SYNC_BOOKING_WITH_ES;
  payload: boolean;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface SetReportLoading {
  type: typeof ACTIONS.SET_REPORT_LOADING;
  payload: boolean;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GetCentre {
  type: typeof ACTIONS.GET_CENTER;
  payload: Array<any>;
}
interface createPartner {
  type: typeof ACTIONS.CREATE_PARTNER;
  payload: Object;
}
interface GetUsersData {
  type: typeof ACTIONS.GET_USER_DATA;
  payload: Object;
}
interface GetPackageAlias {
  type: typeof ACTIONS.GET_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface CreatePackageAlias {
  type: typeof ACTIONS.CREATE_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface UpdatePackageAlias {
  type: typeof ACTIONS.UPDATED_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface GetAgentNewData {
  type: typeof ACTIONS.GET_AGENTS_NEW_DATA;
  payload: Array<any>;
}
interface GetBookingSubDisposition {
  type: typeof ACTIONS.GET_BOOKING_SUB_DISPOSITION;
  payload: boolean;
}
interface GetPanelUserComment {
  type: typeof ACTIONS.GET_PANEL_USER_COMMENT;
  payload: boolean;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}
interface GetPhleboTimeline {
  type: typeof ACTIONS.GET_PHLEBO_TIMELINE;
  payload: Object;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Object;
}
interface CreateLab {
  type: typeof ACTIONS.CREATE_LAB;
  payload: boolean;
}
interface UpdateLab {
  type: typeof ACTIONS.UPDATE_LAB;
  payload: boolean;
}
interface GetLabCredential {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL;
  payload: Array<any>;
}
interface GetLabLog {
  type: typeof ACTIONS.GET_LAB_LOG;
  payload: Array<any>;
}
interface ResetLogs {
  type: typeof ACTIONS.RESET_LOGS;
  payload: boolean;
}
interface UpdateCenter {
  type: typeof ACTIONS.UPDATE_CENTER;
  payload: Array<any>;
}
interface UpdatedDialer {
  type: typeof ACTIONS.UPDATED_DIALER;
  payload: Object;
}

interface GetCenterData {
  type: typeof ACTIONS.GET_CENTER_DATA;
  payload: Object;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetPaginationComment {
  type: typeof ACTIONS.GET_PAGINATION_COMMENT;
  payload: Array<any>;
}
interface SetPhlebosLoading {
  type: typeof ACTIONS.SET_PHLEBOS_LOADING;
  payload: boolean;
}
interface GetPhleboAuto {
  type: typeof ACTIONS.GET_PHLEBOS_AUTO;
  payload: Array<any>;
}
interface GetUserTypes {
  type: typeof ACTIONS.GET_USER_TYPES;
  payload: boolean;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface GetAreaComments {
  type: typeof ACTIONS.GET_AREA_COMMENTS;
  payload: Array<any>;
}
interface UpdatePartner {
  type: typeof ACTIONS.UPDATE_PARTNER;
  payload: Array<any>;
}
interface CreatePartner {
  type: typeof ACTIONS.PARTNER_CREATED;
  payload: Array<any>;
}
interface PartnerLoading {
  type: typeof ACTIONS.SET_PARTNER_LOADING;
  payload: Array<any>;
}
interface UpdatePickUpStatus {
  type: typeof ACTIONS.UPDATE_PICK_UP_STATUS;
  payload: Array<any>;
}
interface SetLoadingCity {
  type: typeof ACTIONS.SET_LOADING_CITY;
  payload: boolean;
}

export type QualityExecutiveTypes =
  | GetLblCategoryList
  | GetLblFaqs
  | CreateFaqs
  | GetVideoCategoryList
  | CreateLblCategory
  | CreateVideoCategory
  | CreateVideo
  | GetVideoList
  | GetVisualAidCategoryList
  | CreateVisualAidCategory
  | GetVisualAidList
  | CreateVisualAid
  | GetAdminCityList
  | GetCityData
  | SetLoadingCity
  | CreateAdminCity 
  | GetAboutUsList
  | CreateAboutUs
  | GetLblList
  | GetLblCategoryData
  | CreateLbl
  | GetCategoryData
  | GetPackageEdos
  | GetLblMoreInfoList
  | GetCategoryList
  | GetLblFaqsData
  | GetLblFaqsDataList
  | DeleteFaqs
  | CreatePanelUser
  | UpdatedPanelUser
  | GetPanelComments
  | GetPanelUserList
  | GetPanelUserDataSalesLead
  | GetPanelUserDataTeamLead
  | SetLoading
  | GetRecordings
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetPartnersAuto
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | AddRemarkOnCall
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetBreakData
  | UpdateCoupon
  | CreateCoupon
  | GetVerificationBookingDetails
  | GetUnmaskedNumber
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetTataTalyLead
  | GetLeadStatus
  | GetPrescriptionData
  | CheckGeoFenceArea
  | GetRedCoin
  | GetAddressTypeSeating
  | CheckGeoFenceAreaMapMyIndia
  | GetMapMyIndiaAccessToken
  | GetUserDetails
  | GetNotificationMessage
  | GetRecordingsBackups
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetQualityScoringReport
  | GetCallsData
  | PostQualityScore
  | GetQualityScoringReportForBackup
  | GetCenterInfo
  | UpdateTicketStatus
  | DisabledUserDetails
  | RaiseNewTicektDisposition
  | GetUserData
  | getOzontelSkillData
  | SetUserLoading
  | UpdatePhlebos
  | GetPhleboComment
  | GetZone
  | CreatePhlebo
  | GetPhleboByPhone
  | GetPaymentInfro
  | GetRiderList
  | UpdateRiderToLabStatus
  | GetSampleHandOver
  | GetSampleJourneyComments
  | GetBatchBookingAmount
  | UpdateRiderSampleHandover
  | UpdateUsersPassword
  | GetTicketComments
  | GetTicketCommentsForDocuments
  | SetNewTicktingLoading
  | GetBookingQueryTickets
  | UpdateActionDisposition
  | GetLanguage
  | GetDialer
  | GetOrgTypes
  | GetChatSkillsList
  | GetAllUsers
  | PostDispositionData
  | GetLeadDispostion
  | GetBookingsLoader
  | GetTimeslots
  | CreateZone
  | GetZoneComment
  | UpdateZone
  | GetCampaignsDetails
  | createDialer
  | GetAssignedUnassignedBooking
  | SetAssignedUnassignedLoader
  | GetMapBooking
  | GetPhleboCurrentLocation
  | GetCityAlias
  | CreateCityAlias
  | GetCityComments
  | GetPackageComments
  | SendInititalWhatsapp
  | GetLtvLead
  | UpdateImagingTicket
  | GetTopRcFreshLeads
  | GetImagingTicket
  | GetWhatsappTemplate
  | CreateWhatsappTemplate
  | InitiateRefund
  | SyncBookingWithEs
  | SendLocationRequest
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | GetSmsReportSent
  | GetSmsDetails
  | GetEmailDetails
  | GetReportStatus
  | GetReportDownload
  | SetReportLoading
  | GetQRCodeData
  | GetSignature
  | GetAdditionalBooking
  | CheckGeoCheckError
  | GeoLoading
  | GetCentre
  | createPartner
  | GetUsersData
  | GetPackageAlias
  | CreatePackageAlias
  | UpdatePackageAlias
  | GetAgentNewData
  | PostRegeneratePyament
  | BookingConfirmtoCancel
  | BookingConfirmtoPending
  | GetBookingSubDisposition
  | GetPanelUserComment
  | GetVerifiedByList
  | GetCollectionSlot
  | GetPhleboTimeline
  | LocationVerified
  | GetAddressDetailsLatLong
  | GetBookingCall
  | GetPhleboData
  | GetUserEdit
  | CreateLab
  | UpdateLab
  | GetLabCredential
  | GetLabLog
  | ResetLogs
  | GetPhleboData
  | UpdateCenter
  | GetCenterData
  | GetSubCenterInformation
  | GetImagingTimeSlots
  | GetDisplayCities
  | GetMultiplepackage
  | GetPaginationComment
  | SetPhlebosLoading
  | GetPhleboAuto
  | GetOtherUsers
  | GetUserTypes
  | CreateArea
  | UpdateArea
  | GetAreaComments
  | SetAreaLoading
  | UpdatePartner
  | CreatePartner
  | PartnerLoading
  | UpdatePickUpStatus;