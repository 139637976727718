import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Switch,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  getUserData,
  disabledUserDetails,
} from "../actions/MedicoappAdminActions";
import { genrateTatTableFilter } from "../../helpers/generateUrl";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
      width: "98%",
    },

    input: {
      padding: "1px",
    },
  })
);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface SampleHandOverProps {
  getUserData: any;
  userTableData: any;
  disabledUserDetails: any;
  loading: Boolean;
}

const BookingsTable: React.FC<SampleHandOverProps> = ({
  getUserData,
  userTableData,
  disabledUserDetails,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [bookingId, setBookingId] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [customer_name, setCustomerName] = useState<any>("");
  const [lead, setLead] = useState<any>("");
  const [customer_mobile, setCustomerMobile] = useState<any>("");
  const [userDataStatus, setUserDataStatus] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState<any>("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = userTableData.links && userTableData.links.next.split("?")[1];
      getUserData(url);
    } else if (newPage < page) {
      let url =
        userTableData.links && userTableData.links.previous.split("?")[1];
      getUserData(url);
    }
    setPage(newPage as number);
  };

  const DisableUserDetailsData = async (pack: any) => {
    const body: any = {
      is_active: !pack.is_active,
    };
    await disabledUserDetails(pack.username, body);
    history.push("/dashboard/mda/userdetails");
  };

  const handleActivateUser = () => {
    DisableUserDetailsData(userDataStatus);
  };

  const handleClickOpen = (data: any) => {
    setUserDataStatus(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterTatTableData = (e: any) => {
    const body: any = {
      id: bookingId,
      phone: phone,
      mobile_number: customer_mobile,
      customer_name: customer_name,
      email,
    };

    const url = genrateTatTableFilter(body).substring(2);
    getUserData(url);
    setPage(0);
  };
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterTatTableData(e);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div style={{ width: "95%" }}>
      <div className={classes.toolbar} />
      <h3 style={{ fontWeight: "bold" }}>User Details</h3>
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="bookingId"
            type="number"
            label="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingId(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="phone"
            type="number"
            label="Phone Number"
            value={phone}
            variant="outlined"
            onChange={(e) => setPhone(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="email"
            type="text"
            label="email"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="customer_name"
            type="text"
            label="Patient Name "
            value={customer_name}
            variant="outlined"
            onChange={(e) => setCustomerName(e.target.value)}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterTatTableData}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/mda/userdetails")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "550px" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  Full Name
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Email
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Phonenumber
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Usergroup
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Username
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Active/Deactive
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {userTableData &&
                userTableData?.results &&
                userTableData.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {data?.fullname ? data?.fullname : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.email ? data?.email : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.phonenumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data.usergroup}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.username}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button onClick={() => handleClickOpen(data)}>
                          <DefaultSwitch
                            name="checkedA"
                            checked={data.is_active}
                          />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  colSpan={3}
                  count={userTableData?.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{" Do You want to Deactivate Centre"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleActivateUser}>Agree</Button>
          </DialogActions>
        </Dialog>
      </div>
      {userTableData?.results && userTableData?.results?.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userTableData: state.MedicoappAdminReducer.userTableData,
  loading: state.MedicoappAdminReducer.loading,
});
export default connect(mapStateToProps, {
  disabledUserDetails,
  getUserData,
})(BookingsTable);
