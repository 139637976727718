import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import styles from "./style.module.css";
import {
  createLbl,
  getLblCategoryData,
  getEdosPackage,
  createLblCategory,
} from "../../actions/MedicoappAdminActions";
import {
  TextField,
  Grid,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
    maxHeight: "700px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    overflow: "scroll",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
  input: {
    height: 40,
  },
  dialog: {
    zIndex: 1,
  },
}));

type ModalProps = {
  open: boolean;
  setOpen: Function;
  createLbl: any;
  getLblCategoryData: any;
  lblCategoryData: any;
  packageEdosData: any;
  getEdosPackage: any;
  loading: any;
  createLblCategory: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  setOpen,
  createLbl,
  getLblCategoryData,
  lblCategoryData,
  packageEdosData,
  getEdosPackage,
  loading,
  createLblCategory,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [lblName, setLblName] = useState<any>("");
  const [isactive, setIsActive] = useState<boolean>(false);
  const [rank, setRank] = useState<any>("");
  // const [file, setFile] = React.useState<any>("");
  const [filePdf, setFilePdf] = React.useState<any>("");
  const [file1, setFile1] = React.useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [images, setImages] = useState<any>(null);
  const [category, setCategory] = useState<any>([]);
  const [packageEdos, setPackageEdos] = useState<any>([]);
  const [showTextField, setShowTextField] = useState(false);
  const [lblCategoryName, setLblCategoryName] = useState("");
  const [rankCate, setRankCate] = useState<any>("");
  const [categoryActive, setCategoryActive] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);

  const handleAddClick = () => {
    setModalStatus(true);
  };

  const handleRemoveClick = () => {
    setModalStatus(false);
    setLblCategoryName("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const blockCharInAutocompleteField = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    return (
      "~!@#$%^&*()_+{}|:<>?,./;'[]=-`".includes(e.key) && e.preventDefault()
    );
  };

  const handleCreateLbl = async (e: any) => {
    e.preventDefault();
    setButtonDisable(true);
    const body: any = {
      name: lblName,
      is_active: isactive,
      rank: rank,
      // file: file,
      // faqs: faqsList,
      images_pdf: filePdf,
      thumbnail: image,
      category: category,
      packages: packageEdos,
    };
    await createLbl(body);
    history.push("/dashboard/mda");
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
  //   const selectedFile = e.target.files[0];
  //   if (selectedFile && selectedFile.type === "application/pdf") {
  //     toBase64(selectedFile).then((data: any) => {
  //       setFile(data);
  //     });
  //   } else {
  //     alert("Please select a PDF file.");
  //   }
  // };
  const fileSelectedHandlerPdf = (e: React.ChangeEvent<{ files: any }>) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      toBase64(selectedFile).then((data: any) => {
        setFilePdf(data);
      });
    } else {
      alert("Please select a PDF file.");
    }
  };

  const fileSelectedHandlerImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
      // Check if the selected file is an image (JPEG, PNG, etc.)
      if (selectedFile.type.startsWith("image/")) {
        toBase64(selectedFile).then((data: any) => {
          setImage(data);
        });
      } else {
        // Handle the case where the selected file is not an image (optional)
        alert("Please select a JPEG or image file.");
      }
    }
  };

  const disableCenter = async (data: any) => {
    openModalFun(data);
  };

  const [modalStatus, setModalStatus] = useState<boolean>(false);

  const openModalFun = (val: any) => {
    setLblCategoryName("");
    setModalStatus(true);
  };

  const closeModal = () => {
    setLblCategoryName("");
    setModalStatus(false);
  };

  const submitModel = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", lblCategoryName);
    formData.append("rank", rankCate);
    formData.append("is_active", String(categoryActive));

    await createLblCategory(formData);
    closeModal();
    setLblCategoryName("");
    setRankCate("");
    setCategoryActive(false);
  };

  useEffect(() => {
    if (buttonDisable) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setButtonDisable(false);
      }, 20000);
    }
  }, [buttonDisable]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <form onSubmit={handleCreateLbl}>
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Create LBL
                </h4>
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="lblName"
                    type="text"
                    value={lblName}
                    className="input"
                    label="Lbl Name"
                    variant="outlined"
                    onChange={(e: any) => setLblName(e.target.value as string)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    name="lead"
                    type="number"
                    label="Rank"
                    value={rank}
                    variant="outlined"
                    onChange={(e) => setRank(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="category"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj.id);
                        });
                        setCategory(packs);
                      }
                    }}
                    options={lblCategoryData || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    loading={loading}
                    multiple
                    getOptionLabel={(option: any) => option.name}
                    getOptionDisabled={(option: any) => {
                      return category?.includes(option?.id);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getLblCategoryData(`search=${newInputValue}&is_active=true`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setCategory([]);
                      }
                    }}
                    classes={{ inputRoot: styles.padding }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Category"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0px !important",
                        }}
                        required={category.length > 0 ? false : true}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                {showTextField ? (
                  <div style={{ marginTop: "5px" }}>
                    <HighlightOffIcon
                      style={{
                        marginTop: "5px",
                        marginBottom: "1rem",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      onClick={handleRemoveClick}
                    />
                  </div>
                ) : (
                  <AddCircleIcon
                    style={{
                      marginTop: "5px",
                      marginBottom: "1rem",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                    onClick={handleAddClick}
                  />
                )}
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="edosPackage"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        sourceIds = obj.map((item: any) => item.id);
                      }
                      setPackageEdos(sourceIds);
                    }}
                    options={packageEdosData || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    loading={loading}
                    multiple
                    getOptionLabel={(option: any) => option.name}
                    getOptionDisabled={(option: any) => {
                      return packageEdos?.includes(option?.id);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEdosPackage(`search=${newInputValue}`);
                      }, 1000);
                      if (newInputValue.length === 0) {
                        setPackageEdos([]);
                      }
                    }}
                    classes={{ inputRoot: styles.padding }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Edos Package"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0px !important",
                        }}
                        required={packageEdos.length > 0 ? false : true}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                        onKeyDown={(e) => {
                          blockCharInAutocompleteField(e);
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginTop: "-10px" }}>
                    <sup
                      style={{
                        fontSize: "11px",
                        marginLeft: "15px",
                        // marginTop: "15px",
                        background: "#fff",
                        color: "rgba(0, 0, 0, 0.54)",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Lbl File upload
                    </sup>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        marginTop: "-15px",
                        padding: "10.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      accept=".pdf" // Only allow PDF files
                      onChange={(e) => fileSelectedHandler(e)}
                      required
                    />
                  </div>
                </Grid> */}
                <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginTop: "-10px" }}>
                    <sup
                      style={{
                        fontSize: "11px",
                        marginLeft: "15px",
                        // marginTop: "15px",
                        background: "#fff",
                        color: "rgba(0, 0, 0, 0.54)",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Lbl File upload
                    </sup>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        marginTop: "-15px",
                        padding: "10.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      accept=".pdf" // Only allow PDF files
                      onChange={(e) => fileSelectedHandlerPdf(e)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginTop: "-10px" }}>
                    <sup
                      style={{
                        fontSize: "11px",
                        marginLeft: "15px",
                        // marginTop: "15px",
                        background: "#fff",
                        color: "rgba(0, 0, 0, 0.54)",
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Thumbnail upload
                    </sup>
                    <input
                      className="input"
                      style={{
                        width: "100%",
                        marginTop: "-15px",
                        padding: "10.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="image"
                      id="image"
                      accept="image/jpeg" // Change "application/pdf" to "image/jpeg"
                      onChange={fileSelectedHandlerImg}
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} md={1} style={{ marginTop: "10px" }}>
                  <InfoIcon color="primary" onClick={handleOpenInfo} />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <div style={{ marginLeft: "40px" }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={isactive}
                      label="IsActive"
                      onChange={(e) => setIsActive(!isactive)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={buttonDisable}
                  >
                    ADD{loading && <CircularProgress size={24} />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => history.push("/dashboard/mda/lbl")}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={modalStatus}
                onClose={closeModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
              >
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <p>Create LBL Category</p>
                      <TextField
                        name="categoryName"
                        type="text"
                        label="Lbl Category Name"
                        value={lblCategoryName}
                        className="input"
                        variant="outlined"
                        onChange={(e) => setLblCategoryName(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        className="input"
                        name="rank"
                        type="number"
                        label="Rank"
                        value={rankCate}
                        variant="outlined"
                        onChange={(e) => setRankCate(e.target.value)}
                        style={{ width: "100%", marginTop: "35px" }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={categoryActive}
                        label="IsActive"
                        onChange={(e) => setCategoryActive(!categoryActive)}
                        style={{ width: "100%", marginTop: "32px" }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={submitModel} color="primary" disabled={lblCategoryName === "" || rankCate === ""}>
                    Save
                  </Button>
                  <Button onClick={closeModal} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openInfo}
                onClose={handleCloseInfo}
                closeAfterTransition
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Fade in={openInfo}>
                  <div
                    style={{
                      position: "absolute",
                      minHeight: "400",
                      minWidth: "500",
                      backgroundColor: "white",
                    }}
                  >
                    <Box>
                      <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <div
                          style={{
                            margin: "0rem 2rem",
                            paddingTop: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4>Note:</h4>
                          <CloseSharpIcon onClick={handleCloseInfo} />
                        </div>
                      </Typography>
                      <Typography
                        id="transition-modal-description"
                        sx={{ mt: 1 }}
                      >
                        <div style={{ margin: "1rem" }}>
                          <div
                            style={{
                              alignItems: "left",
                              display: "inline-block",
                              margin: "1rem",
                            }}
                          >
                            <small>
                              <ul style={{ paddingLeft: "15px" }}>
                                <li
                                  style={{
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  The thumbnail resolution must be 1749 X 2481
                                  pixels.
                                </li>
                              </ul>
                            </small>
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </div>
                </Fade>
              </Modal>
            </div>
          </Fade>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  lblCategoryData: state.MedicoappAdminReducer.lblCategoryData,
  packageEdosData: state.MedicoappAdminReducer.packageEdosData,
});

export default connect(mapStateToProps, {
  createLbl,
  getLblCategoryData,
  getEdosPackage,
  createLblCategory,
})(CommentsModal2);
