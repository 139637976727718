import axios from "axios";

const AXIOS1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_PATH_MEDICO}`,
  headers: {
    "Content-Type": "application/json",
  },

  // timeout: 60000,
});
export default AXIOS1;
