import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import { createVideoCategory } from "../../actions/MedicoappAdminActions";
import { TextField, Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
}));

type ModalProps = {
  loading: any;
  open: boolean;
  setOpen: Function;
  createVideoCategory: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  open,
  setOpen,
  createVideoCategory,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [videoCategoryName, setVideoCategoryName] = useState<any>("");
  const [isactive, setIsActive] = useState<boolean>(false);
  const [rank, setRank] = useState<any>("");

  const handleClose = () => {
    setOpen(false);
  };

  const CreateVideoCategory = async (e: any) => {
    e.preventDefault();
    const body: any = {
      name: videoCategoryName,
      is_active: isactive,
      rank: rank,
    };

    await createVideoCategory(body);
    history.push("/dashboard/mda/video_category_list");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <form onSubmit={CreateVideoCategory}>
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Create Video Category
                </h4>
                <CloseIcon onClick={() => setOpen(false)} />
              </div>
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="videoCategoryName"
                    type="text"
                    value={videoCategoryName}
                    className="input"
                    label=" Video Category Name"
                    variant="outlined"
                    onChange={(e: any) =>
                      setVideoCategoryName(e.target.value as string)
                    }
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    className="input"
                    name="lead"
                    type="number"
                    label="Rank"
                    value={rank}
                    variant="outlined"
                    onChange={(e) => setRank(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div style={{ marginLeft: "40px" }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={isactive}
                      label="IsActive"
                      onChange={(e) => setIsActive(!isactive)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{ width: "100%" }}
                    // onClick={CreateVideoCategory}
                    type="submit"
                  >
                    ADD{loading && <CircularProgress size={24} />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/mda/video_category_list")
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
});

export default connect(mapStateToProps, {
  createVideoCategory,
  useStyles,
})(CommentsModal2);
