import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader2/index";
import { getVideoList, updatedVideo } from "../actions/MedicoappAdminActions";
import VideoModel from "../components/CreateData/addVideoModal";
import EditVideoModel from "../components/EditData/updateVideoModal";
import { genrateVideoCategoryFilter } from "../../helpers/generateUrl";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  videoList: any;
  getVideoList: any;
  data: any;
  updatedVideo: any;
}

const VideoListPage: React.FC<Props> = ({
  loading,
  videoList,
  getVideoList,
  data,
  updatedVideo,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const [editVideoDetails, seteditVideoDetails] = useState<any>({});
  const [editVideoDetailsRank, seteditVideoDetailsRank] = useState<any>({});
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [editVideoCategoryDetails, seteditLblCateDetails] = useState<any>({});
  const [openVideoCategoryModal, setOpenVideoCategoryModal] = useState(false);
  const [rank, setRank] = useState<any>(
    editVideoDetailsRank && editVideoDetailsRank?.rank
  );
  const [image, setImage] = useState<any>("");
  const [categoryVideo, setVideoCategory] = useState<any>(""
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = videoList && videoList?.next.split("?")[1];
      getVideoList(url ? `${url}` : "");
    } else if (newPage < page) {
      let url = videoList && videoList?.previous.split("?")[1];
      getVideoList(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getVideoList();
  }, []);

  const filterBlacklistedNumber = () => {
    const body: any = {
      search: categoryName,
    };
    const url = genrateVideoCategoryFilter(body).substring(2);
    getVideoList(`${url}`);
    setPage(0);
  };

  const handleEditVideo = (data: any) => {
    setOpenVideoModal(true);
    seteditVideoDetails(data);
    setOpenVideoCategoryModal(true);
    seteditLblCateDetails(data);
  };


  const handleUser = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }
    if (categoryVideo) {
      formData.append("category", categoryVideo);
    } else {
      formData.delete("category");
    }
    if (image !== "") {
      formData.append("thumbnail", image);
    }
 
    updatedVideo(formData, editVideoDetailsRank.id);
    setOpenVideoCategoryModal(false);
    history.push("/dashboard/mda/video_list");
  };

  const handleEditRank = (data: any) => {
    setRank(data?.rank); // Set the rank state to the current rank value
    seteditVideoDetailsRank(data);
    setOpenVideoCategoryModal(true);
  };

  const handleChangeRank = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRank(e.target.value); // Update the rank state when the input value changes
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="medico-page">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>Video</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
              >
                Video
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className="input"
                  name="title"
                  type="text"
                  label="Name"
                  value={categoryName}
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading || categoryName === ""}
                  fullWidth
                  onClick={filterBlacklistedNumber}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/mda/video_list")}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Rank</StyledTableCell>
                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {videoList &&
                videoList?.results &&
                videoList?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <div>
                        {editVideoDetailsRank === data ? (
                          <TextField
                            className="input"
                            name="lead"
                            type="number"
                            value={rank}
                            variant="outlined"
                            onChange={handleChangeRank}
                            style={{ width: "50%" }}
                          />
                        ) : (
                          data?.rank
                        )}
                        </div>
                        <div>
                        {editVideoDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                           onClick={(e) => handleUser(e)} 
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                           <EditIcon/>
                          </Button>
                        )}
                        </div>
                      </StyledTableCell>

                      {/* <StyledTableCell align="center">
                        {editVideoDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                           onClick={(e) => handleUser(e)} 
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                            Edit Rank
                          </Button>
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.updated_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.is_active === true ? "Active" : "Inactive"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleEditVideo(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  count={videoList?.count || 0}
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  rowsPerPage={videoList?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <VideoModel open={open} setOpen={setOpen} />
      <EditVideoModel
        loading={loading}
        editVideoDetails={editVideoDetails}
        setOpenVideoModal={setOpenVideoModal}
        openVideoModal={openVideoModal}
        openVideoCategoryModal={openVideoCategoryModal}
        setOpenVideoCategoryModal={setOpenVideoCategoryModal}
        editVideoCategoryDetails={editVideoCategoryDetails}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  videoList: state.MedicoappAdminReducer.videoList,
});

export default connect(mapStateToProps, {
  getVideoList,
  updatedVideo,
})(VideoListPage);
