import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useRef } from "react";
import { updatedLblCategory } from "../../actions/MedicoappAdminActions";
import { TextField, Grid, FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { sentenceCase } from "../../../utils";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
}));

type ModalProps = {
  loading: any;
  editLblCateDetails: any;
  openLblCateModal: boolean;
  setOpenLblCateModal: Function;
  updatedLblCategory: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  editLblCateDetails,
  openLblCateModal,
  setOpenLblCateModal,
  updatedLblCategory,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [categoryName, setCategoryName] = useState<any>(editLblCateDetails &&
    editLblCateDetails.name);
  const [isactive, setIsActive] = useState<boolean>(editLblCateDetails&&editLblCateDetails?.is_active);
  const [rank, setRank] = useState<any>(editLblCateDetails && editLblCateDetails?.rank);

  const handleClose = () => {
    setOpenLblCateModal(false);
  };

  const UpdateLblCategory = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (categoryName) {
      formData.append("name", categoryName);
    } else {
      formData.delete("name");
    }
    if (String(isactive)) {
      formData.append("is_active", String(isactive));
    } else {
      formData.delete("is_active");
    }
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }

    await updatedLblCategory(formData, editLblCateDetails && editLblCateDetails.id);
    history.push("/dashboard/mda/lbl_category_list");
  };

  useEffect(() => {
    setCategoryName(editLblCateDetails?.name);
    setRank(editLblCateDetails?.rank);
    setIsActive(editLblCateDetails?.is_active);
  }, [editLblCateDetails]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLblCateModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openLblCateModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Update LBL Category
              </h4>
              <CloseIcon onClick={() => setOpenLblCateModal(false)} />
            </div>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="categoryName"
                  type="text"
                  value={categoryName}
                  className="input"
                  label="Lbl Category Name"
                  variant="outlined"
                  onChange={(e: any) =>
                    setCategoryName(sentenceCase(e.target.value))
                  }
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  label="Rank"
                  value={rank}
                  variant="outlined"
                  onChange={(e) => setRank(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div style={{marginLeft: "40px"}}>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isactive}
                  label="IsActive"
                  onChange={(e) => setIsActive(!isactive)}
                />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={UpdateLblCategory}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push("/dashboard/mda/lbl_category_list")
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
  updatedLblCategory,
  useStyles,
})(CommentsModal2);
