import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import SearchResultsMain from "./Searchtable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
     
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);
interface Props {
  // search_results: any;
  // loading: boolean;
}

const ASearchResultsMain: React.FC<Props> = ({}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const [leadId, setLeadId] = React.useState<number>(0);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box>
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SearchResultsMain />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  search_results: state.MedicoappAdminReducer.search_results,
  loading: state.MedicoappAdminReducer.loading,
});

export default connect(mapStateToProps)(ASearchResultsMain);
