//Authentication
import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./index.css";
import { NotificationContainer } from "react-notifications";

import "react-notifications/lib/notifications.css";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import Dash from "../containers/dash";
import { checkUser } from "../actions/loginActions";
import { messageBox } from "../actions/notificationAction";
import { connect } from "react-redux";

// MediMedicoappAdmin App Admin
import MedicoappAdminDash from "../containers/MedicoappAdmin-agent/index";
import MedicoappAdminLblCategoryPage from "../MedicoappAdmin-Panel/pages/lblCategoryList";
import MedicoappAdminLblFaqsPage from "../MedicoappAdmin-Panel/pages/lblFaqs";
import MedicoappAdminLblPage from "../MedicoappAdmin-Panel/pages/lblList";
import MedicoappAdminVideoCategory from "../MedicoappAdmin-Panel/pages/videoCategoryList";
import MedicoappAdminVideo from "../MedicoappAdmin-Panel/pages/videoList";
import MedicoappAdminVideoAidCategory from "../MedicoappAdmin-Panel/pages/visualAidCategory";
import MedicoappAdminVisualAid from "../MedicoappAdmin-Panel/pages/visualAidList";
import MedicoappAdminCity from "../MedicoappAdmin-Panel/pages/cityList";
import MedicoappAdminAboutUs from "../MedicoappAdmin-Panel/pages/aboutUsList";
import MedicoappAdminBookingExport from "../MedicoappAdmin-Panel/pages/exportBooking";
import MedicoappAdminBookingsPage from "../MedicoappAdmin-Panel/pages/filter";
import MedicoappAdminLeadExport from "../MedicoappAdmin-Panel/pages/exportLead";
import MedicoappAdminVerifiedBooking from "../MedicoappAdmin-Panel/tables/bookingVerificationTable";
import MedicoappAdminTestpackagreView from "../MedicoappAdmin-Panel/pages/testPackages";
import MedicoappAdminAuditScore from "../MedicoappAdmin-Panel/pages/dispositions";
import MedicoappAdminUserDetails from "../MedicoappAdmin-Panel/tables/UserDetails";
import MedicoappAdminSampleHandoverPage from "../MedicoappAdmin-Panel/tables/sampleHandOverTable";
import MedicoappAdminSearchResultsMain from "../MedicoappAdmin-Panel/components/search/SearchResult";

var jwt = require("jsonwebtoken");

function Routes(props) {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }

  return (
    <BrowserRouter>
      <NotificationContainer />
      <MainLayout>
        <SnackBar />
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            component={() => {
              const token = SecureStorage.getItem("token");
              const decoded = jwt.decode(token);
              if (decoded.user_group === "MedicoappAdmin") {
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/mda"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <MedicoappAdminDash>
                            <Route
                                exact
                                path="/dashboard/mda/lbl"
                                component={MedicoappAdminLblPage}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/lbl_category_list"
                                component={MedicoappAdminLblCategoryPage}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/lbl_faqs"
                                component={MedicoappAdminLblFaqsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/video_category_list"
                                component={MedicoappAdminVideoCategory}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/video_list"
                                component={MedicoappAdminVideo}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/visual_aid_category"
                                component={MedicoappAdminVideoAidCategory}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/visual_aid"
                                component={MedicoappAdminVisualAid}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/city"
                                component={MedicoappAdminCity}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/about_us"
                                component={MedicoappAdminAboutUs}
                              />
                              <Route
                                exact
                                path="/dashboard/mda"
                                component={MedicoappAdminLblPage}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/bookings/export"
                                component={MedicoappAdminBookingExport}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/leads/export"
                                component={MedicoappAdminLeadExport}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/sample_receiving_list"
                                component={MedicoappAdminSampleHandoverPage}
                              />

                              <Route
                                exact
                                path="/dashboard/mda/verifiedbooking"
                                component={MedicoappAdminVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/testPackages"
                                component={MedicoappAdminTestpackagreView}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/auditscore"
                                component={MedicoappAdminAuditScore}
                              />
                              <Route
                                exact
                                path="/dashboard/mda/userdetails"
                                component={MedicoappAdminUserDetails}
                              />
                              <Route
                                path="/dashboard/mda/lead/search"
                                exact
                                component={MedicoappAdminSearchResultsMain}
                              />
                            </MedicoappAdminDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                );
              } else {
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
}

export default connect(null, { checkUser, messageBox })(Routes);
