import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader2/index";
import { getVisualAidCategoryList } from "../actions/MedicoappAdminActions";
import VisualAidCategoryModel from "../components/CreateData/addVisualAidCategoryModal";
import EditVisualAidCateModel from "../components/EditData/updateVisualAidCategoryModal";
import { genrateVideoCategoryFilter } from "../../helpers/generateUrl";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  visualAidCategoryList: any;
  getVisualAidCategoryList: any;
  data: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  visualAidCategoryList,
  getVisualAidCategoryList,
  data,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const [editVisualAidCateDetails, setEditVisualAidCateDetails] = useState<any>(
    {}
  );
  const [openVisualAidCateModal, setOpenVisualAidCateModal] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        visualAidCategoryList && visualAidCategoryList?.next.split("?")[1];
      getVisualAidCategoryList(url ? `${url}` : "");
    } else if (newPage < page) {
      let url =
        visualAidCategoryList && visualAidCategoryList?.previous.split("?")[1];
      getVisualAidCategoryList(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getVisualAidCategoryList();
  }, []);

  const filterBlacklistedNumber = () => {
    const body: any = {
      search: categoryName,
    };
    const url = genrateVideoCategoryFilter(body).substring(2);
    getVisualAidCategoryList(`${url}`);
    setPage(0);
  };

  const handleUpdateVisualAidCate = (data: any) => {
    setOpenVisualAidCateModal(true);
    setEditVisualAidCateDetails(data);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="medico-page">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>Visual Aid Category</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
              >
                Visual Aid Category
              </Button>
            </Grid>
          </Grid>
          <div className={classes.contentTable}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className="input"
                  name="title"
                  type="text"
                  label="Name"
                  value={categoryName}
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading || categoryName === ""}
                  fullWidth
                  onClick={filterBlacklistedNumber}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push("/dashboard/mda/visual_aid_category")
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Rank</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {visualAidCategoryList &&
                visualAidCategoryList?.results &&
                visualAidCategoryList?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {data?.rank}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.updated_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.is_active === true ? "Active" : "Inactive"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleUpdateVisualAidCate(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  count={visualAidCategoryList?.count || 0}
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  rowsPerPage={visualAidCategoryList?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <VisualAidCategoryModel open={open} setOpen={setOpen} />
      <EditVisualAidCateModel
        loading={loading}
        editVisualAidCateDetails={editVisualAidCateDetails}
        setOpenVisualAidCateModal={setOpenVisualAidCateModal}
        openVisualAidCateModal={openVisualAidCateModal}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  visualAidCategoryList: state.MedicoappAdminReducer.visualAidCategoryList,
});

export default connect(mapStateToProps, {
  getVisualAidCategoryList,
})(CustomUploader);
