import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/index";
import { getLblFaqsList,updatedLblFqs,getLblFqsDataList } from "../actions/MedicoappAdminActions";
import LblFaqsModel from "../components/CreateData/addLblFaqsModal";
import EditLblFaqsModel from "../components/EditData/updateLblFaqsModal";
import { genrateLblCategoryFilter } from "../../helpers/generateUrl";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import EditIcon from '@mui/icons-material/Edit';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    contentTable: {
      width: "100%",
      padding: "10px 0px 10px 0px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: any;
  lblFaqsList: any;
  getLblFaqsList: any;
  data: any;
  updatedLblFqs: any;
  getLblFqsDataList: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  lblFaqsList,
  getLblFaqsList,
  data,
  updatedLblFqs,
  getLblFqsDataList,
}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [categoryName, setCategoryName] = useState("");
  const [open, setOpen] = useState(false);
  const [editLblFaqsDetails, seteditLblCateDetails] = useState<any>({});
  const [openLblFaqsModal, setOpenLblFaqsModal] = useState(false);
  const [editLblDetailsRank, seteditLblDetailsRank] = useState<any>({});
  const [rank, setRank] = useState<any>(editLblDetailsRank && editLblDetailsRank?.rank);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = lblFaqsList && lblFaqsList.next.split("?")[1];
      getLblFaqsList(url ? `${url}` : "");
    } else if (newPage < page) {
      let url = lblFaqsList && lblFaqsList.previous.split("?")[1];
      getLblFaqsList(url ? `${url}` : "");
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getLblFaqsList();
  }, []);

  const filterBlacklistedNumber = () => {
    const body: any = {
      search: categoryName,
    };
    const url = genrateLblCategoryFilter(body).substring(2);
    getLblFaqsList(`${url}`);
    setPage(0);
  };

  const handleUpdateLblCate = (data: any) => {
    setOpenLblFaqsModal(true);
    seteditLblCateDetails(data);
    getLblFqsDataList(`?lbl=${data?.lbl[0]}`);
    localStorage.setItem("lblId",data?.lbl[0] )
  };

  const handleEditRank = (data: any) => {
    setRank(data?.rank); // Set the rank state to the current rank value
    seteditLblDetailsRank(data);
  };

  const handleChangeRank = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRank(e.target.value); // Update the rank state when the input value changes
  };

  const handleUser = async (e: any) => {
    e.preventDefault();

    const body: any = {
      rank: rank,

    };
    await updatedLblFqs(body,editLblDetailsRank.id);
    history.push("/dashboard/mda/lbl_faqs");
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div className="medico-page">
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={10}>
              <h2>LBL FAQs</h2>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => setOpen(true)}
                startIcon={<AddIcon />}
              >
                LBL FAQs
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "700px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Rank</StyledTableCell>
                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                <StyledTableCell align="center">Question</StyledTableCell>
                <StyledTableCell align="center">Answer</StyledTableCell>
                <StyledTableCell align="center">LBL Name</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Updated At</StyledTableCell>
                {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                <StyledTableCell align="center">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {lblFaqsList &&
                lblFaqsList?.results &&
                lblFaqsList?.results?.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <div>
                        {editLblDetailsRank === data ? (
                          <TextField
                            className="input"
                            name="lead"
                            type="number"
                            value={rank}
                            variant="outlined"
                            onChange={handleChangeRank}
                            style={{ width: "50%" }}
                          />
                        ) : (
                          data?.rank
                        )}
                        </div>
                        <div>
                        {editLblDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={(e) => handleUser(e)}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                           <EditIcon/>
                          </Button>
                        )}
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {editLblDetailsRank === data ? (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={(e) => handleUser(e)}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => handleEditRank(data)}
                          >
                            Edit Rank
                          </Button>
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {data?.question}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {data?.answer}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                      {data?.lbl_detail?.length > 0 &&
                          data?.lbl_detail?.map((val: any) => {
                            return <Chip size="small" label={val?.name} />;
                          })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(data?.updated_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {data?.is_active === true ? "Active" : "Inactive"}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => handleUpdateLblCate(data)}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  count={lblFaqsList?.count || 0}
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  rowsPerPage={lblFaqsList?.page_size}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <LblFaqsModel open={open} setOpen={setOpen} />
      <EditLblFaqsModel
        // loading={loading}
        editLblFaqsDetails={editLblFaqsDetails}
        setOpenLblFaqsModal={setOpenLblFaqsModal}
        openLblFaqsModal={openLblFaqsModal} 
        />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.MedicoappAdminReducer.loading,
  loadingData: state.MedicoappAdminReducer.loadingData,
  lblFaqsList: state.MedicoappAdminReducer.lblFaqsList,
});

export default connect(mapStateToProps, {
  getLblFaqsList,
  updatedLblFqs,
  getLblFqsDataList
})(CustomUploader);
