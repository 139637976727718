export function sentenceCase(param: string) {
  const spaceLogic =
    param.trim() === "" ? "" : param.replace(/[^a-zA-Z0-9\s]/g, "");
  const titleCase = spaceLogic.split(" ").map((word: string) => {
    if (word.length === 0) {
      return "";
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return titleCase.join(" ");
  //   return spaceLogic.length === 1
  //     ? spaceLogic.toUpperCase()
  //     : spaceLogic.charAt(0).toUpperCase() +
  //         spaceLogic.split("").slice(1).join("").toLowerCase();
}
