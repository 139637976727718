import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@material-ui/icons/Close";
import { updatedVisualAidCategory } from "../../actions/MedicoappAdminActions";
import {
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { sentenceCase } from "../../../utils";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  inputFile: {
    border: "1px solid #0000003b",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
  },
}));

type ModalProps = {
  loading: any;
  editVisualAidCateDetails: any;
  setOpenVisualAidCateModal: Function;
  updatedVisualAidCategory: any;
  openVisualAidCateModal: boolean;
};

const CommentsModal2: React.FC<ModalProps> = ({
  loading,
  editVisualAidCateDetails,
  setOpenVisualAidCateModal,
  updatedVisualAidCategory,
  openVisualAidCateModal,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [visualAidCatName, setVisualAidCatName] = useState<any>(
    editVisualAidCateDetails && editVisualAidCateDetails?.name
  );
  const [isactive, setIsActive] = useState<boolean>(
    editVisualAidCateDetails && editVisualAidCateDetails?.is_active
  );
  const [rank, setRank] = useState<any>(
    editVisualAidCateDetails && editVisualAidCateDetails?.rank
  );
  const [image, setImage] = useState<any>("");

  const handleClose = () => {
    setOpenVisualAidCateModal(false);
  };

  const UpdateVisualAidCategory = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (visualAidCatName) {
      formData.append("name", visualAidCatName);
    } else {
      formData.delete("name");
    }
    if (String(isactive)) {
      formData.append("is_active", String(isactive));
    } else {
      formData.delete("is_active");
    }
    if (rank) {
      formData.append("rank", rank);
    } else {
      formData.delete("rank");
    }
    if (image !== "") {
      formData.append("thumbnail", image);
    }

    await updatedVisualAidCategory(
      formData,
      editVisualAidCateDetails && editVisualAidCateDetails?.id
    );
    history.push("/dashboard/mda/visual_aid_category");
  };

  const fileSelectedHandlerImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    setVisualAidCatName(editVisualAidCateDetails?.name);
    setRank(editVisualAidCateDetails?.rank);
    setIsActive(editVisualAidCateDetails?.is_active);
  }, [editVisualAidCateDetails]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openVisualAidCateModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openVisualAidCateModal}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <h4
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Update Visual Aid Category
              </h4>
              <CloseIcon onClick={() => setOpenVisualAidCateModal(false)} />
            </div>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="visualAidCatName"
                  type="text"
                  value={visualAidCatName}
                  className="input"
                  label="Visual Aid Category Name"
                  variant="outlined"
                  onChange={(e: any) =>
                    setVisualAidCatName(sentenceCase(e.target.value))
                  }
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className="input"
                  name="lead"
                  type="number"
                  label="Rank"
                  value={rank}
                  variant="outlined"
                  onChange={(e) => setRank(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={6}
                md={3}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <InputLabel
                  htmlFor="reciept-proof"
                  style={{ paddingTop: "5px" }}
                >
                  <sup>Image upload</sup>
                </InputLabel>
                <input
                  className="input"
                  style={{
                    width: "100%",
                    marginTop: "-2px",
                    marginBottom: "10px",
                    padding: "5px 5px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                  }}
                  type="file"
                  name="image"
                  id="image"
                  accept="image/jpeg"
                  onChange={fileSelectedHandlerImg}
                  required
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ marginTop: "-10px" }}>
                  <sup
                    style={{
                      fontSize: "11px",
                      marginLeft: "15px",
                      // marginTop: "15px",
                      background: "#fff",
                      color: "rgba(0, 0, 0, 0.54)",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Image upload
                  </sup>
                  <input
                    className="input"
                    style={{
                      width: "100%",
                      marginTop: "-15px",
                      padding: "10.5px 14px",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "5px",
                    }}
                    type="file"
                    name="image"
                    id="image"
                    accept="image/jpeg"
                    onChange={fileSelectedHandlerImg}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <div style={{ marginLeft: "40px" }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={isactive}
                    label="IsActive"
                    onChange={(e) => setIsActive(!isactive)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  style={{ width: "100%" }}
                  onClick={UpdateVisualAidCategory}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push("/dashboard/mda/visual_aid_category")
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  updatedVisualAidCategory,
})(CommentsModal2);
